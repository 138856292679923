/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { resolve } from 'rsvp';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import window from 'ember-window-mock';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';
import { next } from '@ember/runloop';
import { findDoiInInput } from '../utils/doi-utils';
import { findPmidInInput } from '../utils/pmid-utils';

export default class WayflessArticleOrPmidRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service analytics;
  @service applicationSession;
  @service crossref;
  @service pubmed;
  @service unpaywall;
  @service intl;
  @service router;
  @service store;
  @service flashMessages;
  @service media;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    const applicationSession = this.applicationSession;

    if (
      queryParams.utm_source &&
      queryParams.utm_source.toLowerCase().includes("api_")
    ) {
      analytics.setProductDimension("LibkeyAPI");
      this.libkeyProduct = "LibkeyAPI";
    } else if (
      queryParams.utm_source &&
      queryParams.utm_source.toLowerCase() === "nomad"
    ) {
      analytics.setProductDimension("LibkeyNomad");
      this.libkeyProduct = "LibkeyNomad";
    } else if (!analytics.getProductDimension()) {
      analytics.setProductDimension("LibkeyIO");
      this.libkeyProduct = "LibkeyIO";
    } else {
      //We have to get libkeyProduct from the productDimension in order to preserve
      //product of nomad or api that's set elsewhere when handling retraction notice url.
      this.libkeyProduct = analytics.getProductDimension();
    }
    if (applicationSession.transitioningFromLandingPage) {
      this.rightPanelCoverClass = "visible";
      this.transitioningFromLandingPage = false;
    }

    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    if (queryParams.lendingLibraryLookupFailed) {
      this.lendingLibraryLookupFailed = true;
    }

    const libraryId = params.library_id;
    applicationSession.set('selectedLibrary', libraryId);

    let libraryToken;

    await this.handleLocaleOnLibraryRoute();

    if (libraryId && libraryId !== 'unaffiliated') {
      analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
      libraryToken = applicationSession.lookupLibraryToken(libraryId);
      if (!libraryToken) {
        return this.auth.authenticateLibrary({libraryId}, transition);
      }
    }
  }

  async model(params) {
    const libraryId = params.library_id;
    let reloadLibrary = false;
    if (libraryId !== "unaffiliated" && this.libraryNeedsReload) {
      this.libraryNeedsReload = false;
      reloadLibrary = true;
    }
    const applicationSession = this.applicationSession;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    /*
    TODO: Ideally we'd call `findRecord` here instead of `queryRecord`, with "adapterOptions", something like:
     findRecord('library', libraryId, { adapterOptions: { ill_library_group_id: holdingsToolLibraryGroup.id }, reload: true });
     And then in the library adapter, override `buildQuery` and add "ill_library_group_id" to the query there.
      We can't do that now because of this issue: https://github.com/emberjs/data/issues/5373
      The fix for that hasn't yet made it into any ember-data 2.X release.
    */
    const findLibrary = libraryId !== 'unaffiliated' ? this.store.queryRecord(
      'library',
      {
        id: libraryId,
        ill_library_group_id: holdingsToolLibraryGroup ? holdingsToolLibraryGroup.id : undefined,
        reload: reloadLibrary,
      }
    ): undefined;
    const id = params.id_value;
    // Save the requestedId so we can redirect to the equivalent wayf
    // route with it
    this.requestedId = id;

    let findArticleWithJournalAndIssue,
      parseResult,
      parsedDoi,
      parsedPmid,
      doiLink,
      pubmedLink,
      article;

    let avoidUnpaywall;
    const doiResult = findDoiInInput(id);
    const pmidResult = findPmidInInput(id);
    if (doiResult.foundDoi && doiResult.doi !== id) {
      return this.router.transitionTo('wayfless-article-or-pmid', libraryId, doiResult.doi);
    } else if (doiResult.foundDoi) {
      const doi = doiResult.doi;
      try {
        article = await this.store.queryRecord('article', { doi: doi, reload: true, include: 'issue,journal', ill_library_group_id: holdingsToolLibraryGroup ? holdingsToolLibraryGroup.id : undefined});
      } catch(err) {
        if (err.status !== 404) {
          throw err;
        }
        if (err.payload?.meta?.avoidUnpaywall) {
          avoidUnpaywall = err.payload.meta.avoidUnpaywall;
        }
      }
      if (article && article.illLibraryId) {
        return this.router.transitionTo('holdings-tool-fulfillment', libraryId, doiResult.doi);
      }
      if (!article && doi.endsWith('.')) {
        // Article lookup failed.  If the doi ends with a period see if we
        // find something if we slice off the period
        const doiWithoutPeriod = doi.slice(0, doi.length - 1);
        let articleForDoiWithoutPeriod;
        try {
          articleForDoiWithoutPeriod = await this.store.queryRecord('article', { doi: doiWithoutPeriod, reload: true, include: 'issue,journal' });
        } catch(err) {
          if (err.status !== 404) {
            throw err;
          }
        }
        if (articleForDoiWithoutPeriod) {
          // Found an article.  Redirect so the location bar has the correct DOI
          return this.router.transitionTo('wayfless-article-or-pmid', libraryId, doiWithoutPeriod);
        }
      }
      parseResult = 'found_doi';
      parsedDoi = doi;
      doiLink = `https://doi.org/${doi}`;
      findArticleWithJournalAndIssue = resolve(article);
    } else if (pmidResult.foundPmid && pmidResult.pmid != id) {
      return this.router.transitionTo('wayfless-article-or-pmid', libraryId, pmidResult.pmid);
    } else if (pmidResult.foundPmid) {
      const pmid = pmidResult.pmid;
      try {
        article = await this.store.queryRecord('article', { pmid, reload: true, include: 'issue,journal', ill_library_group_id: holdingsToolLibraryGroup ? holdingsToolLibraryGroup.id : undefined })
      } catch (err) {
        if (err.status !== 404) {
            throw err;
        }
      }
      if (article && article.illLibraryId) {
        return this.router.transitionTo('holdings-tool-fulfillment', libraryId, pmid);
      }
      parseResult = 'found_pmid';
      parsedPmid = pmid;
      pubmedLink = `https://www.ncbi.nlm.nih.gov/pubmed/${pmid}`;
      findArticleWithJournalAndIssue = resolve(article);
    } else {
      findArticleWithJournalAndIssue = resolve();
      parseResult = 'unknown_id_type';
    }

    const library = await findLibrary;

    let getUnpaywallData;

    if (!!library && !library.libkeyFallbackUrl) {
      console.log('Library had no libkeyFallbackUrl, libkey has nothing it can display if a DOI article lookup fails');
      return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
    }

    // Check if library has an active libkey subscription
    if (!!library && !library?.subscriptions?.libkey?.active) {
      if (this.disableRedirect) {
        return;
      }
      this.flashMessages.alert('This library is not subscribed to LibKey.');
      window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
      return;
    }

    const abandonedArticle = article && article.abandoned;

    if (!article &&
      !!parsedDoi &&
      (!library || library.useLiveUnpaywallCalls) &&
      !avoidUnpaywall) {
      getUnpaywallData = this.unpaywall.getUnpaywallUrls(parsedDoi);
    } else if (!!article &&
      (
        (
          !library && !article.openAccess
        )
        ||
        (
          !article.contentLocation &&
          !article.fullTextFile
        )
      ) &&
      article.doi &&
      !abandonedArticle &&
      (!article.unpaywallDataSuppressed) &&
      (!library || library.useLiveUnpaywallCalls)) {

      getUnpaywallData = this.unpaywall.getUnpaywallUrls(article.doi);
    }

    let unpaywallUrls = await getUnpaywallData;
    if (article && article.avoidUnpaywallPublisherLinks && unpaywallUrls && unpaywallUrls.linkHostType === 'publisher') {
      unpaywallUrls = undefined;
    }

    let crossrefArticle;

    if (parsedDoi && !abandonedArticle) {
      crossrefArticle = await this.crossref.getArticle(parsedDoi);
    }

    let pubmedArticle;

    if (!article && !crossrefArticle && parsedPmid) {
      pubmedArticle = await this.pubmed.getArticle(parsedPmid);
    }

    const formatPreference = this.applicationSession.formatPreference;
    const articleRetracted = article && article.retractionDoi;
    const articleHasExpressionOfConcern = article && article.expressionOfConcernDoi;
    const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');

    const now = new Date();
    if (!formatPreference || (Date.parse(formatPreference.expiresAt) < now) || articleRetracted || articleHasExpressionOfConcern || journalIsProblematic) {
      // either no format preference in effect or the article is retracted or EOC,
      // show format chooser if there are options to choose from
      return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
        .then((model) => {
          return {
            ...model,
            parseResult,
            parsedDoi,
            parsedPmid,
            doiLink,
            pubmedLink,
            unpaywallUrls,
            crossrefArticle,
            pubmedArticle,
            holdingsToolLibraryGroup,
            showRememberChoiceCheckbox: true
          };
        });
    }

    if (formatPreference.format === 'pdf' && !!article && !!article.fullTextFile) {
      this.router.transitionTo('article-full-text-file', libraryId, article.id );
      return;
    }

    if (formatPreference.format === 'web-link' && !!article && !!article.contentLocation) {
      this.router.transitionTo('new-article-content-location', libraryId, article.id );
      return;
    }

    return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
      .then((model) => {
        return {
          ...model,
          parseResult,
          parsedDoi,
          parsedPmid,
          doiLink,
          pubmedLink,
          unpaywallUrls,
          crossrefArticle,
          pubmedArticle,
          holdingsToolLibraryGroup,
          showRememberChoiceCheckbox: false
        };
      });

  }

  async afterModel(model) {
    if (model.holdingsToolLibraryGroup && !model.library.holdingsToolRequestingEnabled) {
      return this.router.transitionTo('choose-library-no-article');
    }
    this.hasFullTextFile = true;
    if (model.holdingsToolLibraryGroup) {
      const requestingLibraryId = model.library.id;
      const requestingLibraryName = model.library.name;
      const libraryGroupId = model.holdingsToolLibraryGroup.id;
      const event = {
        requestingLibraryId,
        requestingLibraryName,
        libraryGroupId,
      };
      if (model.article && !model.article.abandoned) {
        event.articleId = model.article.id;
        event.doi = model.article.doi;
        event.pmid = model.article.pmid;
        event.openAccess = model.article.openAccess;
        event.articleYear = model.article.year;
        event.issueYear = model.issue ? model.issue.year : '';
        event.authors = model.article.authors;
        event.journalName = model.journal.title;
        event.issn = model.journal.issn;
        event.eissn = model.journal.eissn;
        if (model.article.contentLocation || model.article.fullTextFile) {
          event.category = 'ArticleLookupAvailableAtRequestingLibrary';
        } else {
          event.category = 'ArticleLookupNotAvailable';
        }
      } else {
        event.category = 'ArticleLookupNotFound';
      }
      try {
        await this.analytics.recordEvent(event);
      } catch (err) {
        console.log(`Error in recordEvent(${JSON.stringify(event)}): ${err.message||err.stack}`);
      }
    }
  }

  setupController(controller, model) {
    controller.set('rightPanelCoverClass', this.rightPanelCoverClass);
    controller.set('article', model.article);
    // For attributes that may change when the library changes,
    // bind the UI to the specific attribute values, not
    // the article model directly so that they don't change when a transition
    // updates the article model for a different library.
    if (model.article) {
      controller.set('contentLocation', model.article.contentLocation);
      controller.set('fullTextFile', model.article.fullTextFile);
      controller.set('abandoned', model.article.abandoned);
    }

    controller.set('requestedId', this.requestedId);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('holdingsToolLibraryGroup', model.holdingsToolLibraryGroup);
    if (model.library && model.holdingsToolLibraryGroup) {
      if (this.lendingLibraryLookupFailed) {
        controller.set('lendingLibraryLookupFailed', true);
      } else {
        controller.set('lendingLibraryLookupFailed', false);
        controller.set('requestFromAnotherLibraryLink',`/libraries/${model.library.id}/fulfillment/${this.requestedId}`);
      }
    }
    controller.set('journal', model.journal);
    controller.set('parseResult', model.parseResult);

    let doiFallbackUrl;
    let pmidFallbackUrl;

    if (model.library) {
      if (model.parseResult === 'found_doi' && model.library.libkeyFallbackTemplate) {
        doiFallbackUrl = model.library.libkeyFallbackTemplate
          .replace('{suspected_doi}', model.parsedDoi)
          .replace('{suspected_doi_encoded}', encodeURIComponent(model.parsedDoi));

        controller.set('fallbackTemplateUrl', doiFallbackUrl);

        const fallbackTemplateLabel = model.library.libkeyFallbackTemplateLabel;
        controller.set('fallbackTemplateLabel', fallbackTemplateLabel);
      } else if (model.parseResult === 'found_pmid' && model.article && model.article.pubmedFallbackURL) {
        pmidFallbackUrl = model.article.pubmedFallbackURL;
        controller.set('fallbackTemplateUrl', pmidFallbackUrl);

        const pmidFallbackLabel = model.library.pubmedFallbackTemplateLabel;
        controller.set('fallbackTemplateLabel', pmidFallbackLabel);
      } else if (model.parseResult === 'found_pmid' && model.library.pubmedFallbackTemplate) {
        pmidFallbackUrl = model.library.pubmedFallbackTemplate + model.parsedPmid;
        controller.set('fallbackTemplateUrl', pmidFallbackUrl);

        const pmidFallbackLabel = model.library.pubmedFallbackTemplateLabel;
        controller.set('fallbackTemplateLabel', pmidFallbackLabel);
      } else {
        controller.set('fallbackUrl', model.library.libkeyFallbackUrl);
        controller.set('fallbackLabel', model.library.libkeyFallbackUrlLabel);
      }
    }
    controller.set('doiLink', model.doiLink);
    controller.set('pubmedLink', model.pubmedLink);
    controller.set('unpaywallUrls', model.unpaywallUrls);
    controller.set('crossrefArticle', model.crossrefArticle);
    controller.set('pubmedArticle', model.pubmedArticle);
    controller.set('hasFullTextFile', this.hasFullTextFile);
    controller.set('loadingType', 'fullTextFile');
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('refSystem', this.refSystem);

    if (model.article && model.article.linkResolverOpenurlLink) {
      controller.set('accessOptionsUrl', model.article.linkResolverOpenurlLink);
    } else if (doiFallbackUrl) {
      controller.set('accessOptionsUrl', doiFallbackUrl);
    } else if (pmidFallbackUrl) {
      controller.set('accessOptionsUrl', pmidFallbackUrl);
    } else if (model.library && model.library.linkResolverUrlBase && (model.parseResult === 'found_pmid' || model.parseResult === 'found_doi')) {
      controller.set('accessOptionsUrl', `${model.library.linkResolverUrlBase}${window.location.search}`);
    } else {
      controller.set('accessOptionsUrl', ``);
    }
    controller.set('libkeyProduct', this.libkeyProduct);
    next(() => {
      if (this.media.isMobile || this.media.isTabletportrait) {
        window.scrollTo(0, 0);
      }
      if (!controller.isDestroyed) {
        controller.set("rightPanelCoverClass", "fade-out");
        controller.set("leftPanelContentClass", "fade-in");
      }
    });
  }

  @action
  loading/*transition*/() {
    return false;
  }

  @action
  error(error, transition) {
    let { to: { params } } = transition;
    if (error instanceof ApiSessionExpiredError || error instanceof AuthRequiredError) {
      const libraryId = params.library_id;
      this.handleAPIAuthDemandError(error, libraryId, transition);
    }

    if (error.status === 404) {
      const pubmedFallbackURL = error.payload.errors[0].links.pubmedFallbackURL;

      if (this.disableRedirect) {
        return;
      }

      if (pubmedFallbackURL) {
        window.location.replace(pubmedFallbackURL);
      } else {
        window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
      }
    }

    if (error.status === 401) {
      const applicationSession = this.applicationSession;
      const currentLibraryId = params.library_id;

      applicationSession.clearLibraryToken(currentLibraryId);
      this.reload();
    }

    // Allow error to bubble so error route displays
    return true;
  }

  @action
  changeLibrary() {
    const id = this.requestedId;
    this.applicationSession.set('selectedLibrary', undefined);
    this.router.transitionTo('choose-library', id);
  }
}
