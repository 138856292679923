/* eslint-disable no-setter-return */
import Service from '@ember/service';
import generateUUID from 'libkey-web/utils/generate-uuid';
import {
  getLocalStorageJsonProperty,
  setLocalStorageJsonProperty
} from 'libkey-web/utils/local-storage-json-property';
const userDataKey = `browzine-logged-in-user-data`;
const browserIdKey = 'browzine-browser-identifier';
const libraryTokenKeyPrefix = 'browzine-library-token-';
const formatPreferenceKey = `libkey-format-preference`;
const selectedLibraryKey = 'libkey-selected-library';
const localeOverrideKey = 'libkey-locale-override';

function getStorageKeyForLibraryToken(libraryId) {
  return `${libraryTokenKeyPrefix}${libraryId}`;
}

/*
 * An abstraction around
 * application state that's
 * persisted on the client
 */

export default class ApplicationSession extends Service {
  transitionToRetryAfterAuth = null

  // the selected library prior to a transition's execution is stored
  // here so we can restore it on error
  originalSelectedLibrary = null

  get selectedLibrary () {
    return getLocalStorageJsonProperty(selectedLibraryKey);
  }
  set selectedLibrary(value) {
    return setLocalStorageJsonProperty(selectedLibraryKey, value);
  }
  clearSelectedLibrary() {
    this.selectedLibrary = undefined
  }

  // We just need a string here without any json transforms, so we can use localStorage directly
  get mostRecentLibrary() {
    return localStorage.getItem('BZ_mostRecentLibrary');
  }
  set mostRecentLibrary(value) {
    localStorage.setItem('BZ_mostRecentLibrary', value.toString())
    return value.toString();
  }

  get loggedInUser() {
    return getLocalStorageJsonProperty(userDataKey);
  }
  set loggedInUser(value) {
    return setLocalStorageJsonProperty(userDataKey, value);
  }

  get formatPreference() {
    return getLocalStorageJsonProperty(formatPreferenceKey);
  }
  set formatPreference(value) {
    return setLocalStorageJsonProperty(formatPreferenceKey, value);
  }

  get localeOverride() {
    return getLocalStorageJsonProperty(localeOverrideKey);
  }
  set localeOverride(value) {
    return setLocalStorageJsonProperty(localeOverrideKey, value);
  }

  getBrowserIdentifier() {
    const browserId = localStorage.getItem(browserIdKey);

    if (browserId) {
      return browserId;
    } else {
      const newId = generateUUID();

      localStorage.setItem(browserIdKey, newId);
      return newId;
    }
  }

  get userIsLoggedIn() {
    return !!this.loggedInUser;
  }

  setLoggedInUser(userAuthInfo) {
    this.loggedInUser = userAuthInfo
  }

  clearLoggedInUser() {
    this.loggedInUser =  null
  }

  lookupLibraryToken(libraryId) {
    const bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);
    return getLocalStorageJsonProperty(bzwebLibraryTokenKey);
  }

  /***
   * libraryId: the library associated with the token getting stored
   * tokenObject: {
   *   token: token value
   *   expires_at: UTC timestamp when token expires
   * }
   */
  storeTokenForLibrary(libraryId, tokenObject) {
    const bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);
    setLocalStorageJsonProperty(bzwebLibraryTokenKey, JSON.stringify(tokenObject));
    localStorage.setItem(bzwebLibraryTokenKey, JSON.stringify(tokenObject));

    this.mostRecentLibrary = libraryId;
  }

  clearLibraryToken(libraryId) {
    const bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);
    setLocalStorageJsonProperty(bzwebLibraryTokenKey, undefined);

    localStorage.removeItem(bzwebLibraryTokenKey);
  }

  /**
   * Seems unlikely to be used in production, but useful during testing
   * to initialize to a clean slate
   */
  clearAllLibraryTokens() {
    const keysToRemove = [];
    for (let i=0;i<localStorage.length;i++) {
      if (localStorage.key(i).indexOf(libraryTokenKeyPrefix) !== -1) {
        keysToRemove.push(localStorage.key(i));
      }
    }

    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
  }
}
