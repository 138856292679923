import { helper as buildHelper } from '@ember/component/helper';

/**
  `truncate-text` truncates a string to a given length, optionally at a given
  delimiter, and appends an optional omission string.

  @param {string} text - String to truncate
  @param {Object} options
  @param {number} [options.length=30] - Maximum length to truncate to
  @param {string} [options.delimiter] - Character at which to truncate
  @param {string} [options.omission] - Appended to the truncated string
*/
export function truncateText(params, hash) {
  var text = params[0] || '';
  hash = hash || {length: 30};

  var length = hash.length;
  var delimiter = hash.delimiter;
  var omission;

  if (text.length < length) {
    omission = '';
  } else {
    omission = hash.omission || '';
  }

  var truncated = text.substr(0, length);

  if (delimiter) {
    var lastDelimiter = truncated.lastIndexOf(delimiter);

    if (omission === '' || lastDelimiter === -1) {
      lastDelimiter = truncated.length;
    }

    return truncated.substr(0, lastDelimiter) + omission;
  } else {
    return truncated + omission;
  }
}

export default buildHelper(truncateText);
