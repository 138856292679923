export default function articlePages(article) {
  if (!article?.startPage && !article?.endPage) {
    return "";
  }

  let pages = `pp. ${article?.startPage}-${article?.endPage}`;
  if (!article?.endPage || article?.startPage === article?.endPage) {
    pages = `p. ${article?.startPage}`;
  }

  return pages;
}
