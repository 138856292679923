import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class ProblematicDomainPanel extends Component {
  @service analytics;

  @action
  async didInsert() {
    if (!!this.args.library && !!this.args.domain) {
      await this.analytics.recordEvent({
        category: 'problematicDomainNoticeViewed',
        action: 'NoticeViewed',
        label: '',
        value: this.args.domain.id
      });
    }
  }

}

