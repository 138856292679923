import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';


function ajaxOptions() {
  let options = this._super(...arguments);
  let originalBeforeSend = options.beforeSend;

  options.beforeSend = (xhr) => {
    const intl = this.intl;
    const primaryLocale = intl.primaryLocale || 'en-us';

    xhr.setRequestHeader('Accept-Language', primaryLocale);

    if (originalBeforeSend) {
      originalBeforeSend(xhr);
    }
  };

  return options;
}

/**
 * Use this mixin to setup ajaxOptions to send
 * the current locale on the Accept-Language header
 */

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  intl: service(),

  ajaxOptions,
  moreAjaxOptions: ajaxOptions, // to be referenced by other mixins overloading ajaxOptions

});
