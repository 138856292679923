import Service from '@ember/service';


// Service to replace Ember.testing
//
// Tests are expected to inject their own
// instance of the service with an
// implementation of isTesting that returns
// true.

export default class TestingService extends Service {
  isTesting() {
    return false;
  }
}
