import { Factory, association } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  library: association(),
  journal: association(),

  id(i) {
    return `I${i + 1}`;
  },

  // This is to get around a serialization issue in the mirage server due to the fact that the issues are serialized as plain json
  type: 'issue',

  title() {
    return `Vol. ${faker.number.int({
      min: 1,
      max: 100,
    })} Issue ${faker.number.int({ min: 1, max: 12 })}`;
  },

  date() {
    return faker.date.past({ years: 3 }).toISOString().split('T')[0]; // Generates a past date within the last 10 years
  }
});
