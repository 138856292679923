import Model, { attr } from "@ember-data/model";

export default class LibraryGroup extends Model {
  @attr('string')
  name;

  @attr('string')
  holdings_tool_logo_url;

  @attr('string')
  holdings_tool_subdomain;
}
