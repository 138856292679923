/* eslint-disable ember/no-jquery, ember/no-mixins */
import { action } from '@ember/object';

import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import TriggerOnEnter from 'libkey-web/mixins/trigger-on-enter';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import $ from 'jquery';

export default class ProblematicDomainPageController extends Controller.extend(TriggerOnEnter) {
  @service applicationSession;

  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;

  async performDoubleFade() {
    this.leftPanelContentClass = "fade-out";
    this.rightPanelCoverClass = "fade-in";

    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  @action
  fadeOutChangeLibrary() {
    this.applicationSession.selectedLibrary = undefined
    return this.performDoubleFade();
  }

  @action
  hideResponsiveLanguages() {
    $('.language-list-component').css('transform', 'translate(0,0)');
    $('.language-list-component').addClass('test-hidden');
    $('.page-container').css('transform', 'translate(0,0)');
  }

}

