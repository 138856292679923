import Mixin from '@ember/object/mixin';

const LIBRARY_SESSION_EXPIRED_CODE = 'expired_library_session';


import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';

/**
 * Interprets a JSON API error payload.  When the error corresponds to a
 * custom error type defined in the app, returns the appropriate custom error type.
 * Otherwise returns nothing
 */

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  interpretError(httpStatus, errorPayload) {
    if (httpStatus === 401 &&
        errorPayload.code === LIBRARY_SESSION_EXPIRED_CODE) {
      // The docs suggest DS.InvalidError may not be appropriate for session expirations
      let error = new ApiSessionExpiredError(errorPayload);
      return error;
    } else if (httpStatus === 401) {
      let error = new AuthRequiredError(errorPayload);
      return error;
    }
  },

  // The back-end is transitioning from an old format where
  // the error object is the top level object to one that
  // fits the JSON API error response spec, where the error object
  // is in an array held by the errors property of the top level
  // object.  This makes BZWeb forward-compatible with the
  // upcoming new format.
  getErrorObjectFromErrorResponse(errorResponse) {
    if (errorResponse.errors && errorResponse.errors.length) {
      return errorResponse.errors[0];
    }

    if (errorResponse.length) {
      return errorResponse[0];
    }

    return errorResponse;
  }

});
