/* eslint-disable ember/no-mixins */
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from 'libkey-web/config/environment';
import BelongsToLibraryAdapter from '../mixins/belongs-to-library-adapter';
import AuthenticatingAdapter from '../mixins/authenticating-adapter';
import LocalizedAdapter from '../mixins/localized-adapter';

export default class Journal extends JSONAPIAdapter.extend(LocalizedAdapter, AuthenticatingAdapter, BelongsToLibraryAdapter) {
  namespace = config.apiNamespace;
  host = config.apiHost;

  handleResponse(status, headers, payload) {
    const unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

    if (unauthorizedError) {
      return unauthorizedError;
    }

    return super.handleResponse(...arguments);
  }
}
