import Model, { attr } from "@ember-data/model";

export default class ProblematicDomainModel extends Model {
  @attr('string')
  problematicDomainName;

  @attr('string')
  problematicDomainCabellsUrl;

  @attr('number')
  problematicDomainJournalCount;

  @attr()
  problematicDomainTopJournals;

  @attr()
  problematicDomainTopViolations;
}
