/* eslint-disable ember/no-mixins */
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import _ from 'lodash';
import config from 'libkey-web/config/environment';
import AuthenticatingAdapter from '../mixins/authenticating-adapter';
import LocalizedAdapter from '../mixins/localized-adapter';

export default class Library extends JSONAPIAdapter.extend(LocalizedAdapter, AuthenticatingAdapter) {
  namespace = config.apiNamespace;
  host = config.apiHost;

  updateRecord(store, type, snapshot) {
    const data = {};
    const serializer = store.serializerFor(type.modelName);
    const id = snapshot.id;
    const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
    const changedAttributes = _.keys(snapshot.changedAttributes());

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
    data.data.attributes = _.pick(data.data.attributes, changedAttributes);

    return this.ajax(url, 'PATCH', { data: data });
  }

  handleResponse(status, headers, payload) {
    const unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

    if (unauthorizedError) {
      return unauthorizedError;
    }
    return super.handleResponse(...arguments);
  }

  shouldReloadAll() {
    return true;
  }

  urlForQueryRecord(query, modelName) {
    if (!query.id) {
      return super.urlForQueryRecord(query, modelName);
    }
    const host = this.host;
    const prefix = this.urlPrefix();
    let url = [];
    const path = this.pathForType(modelName);
    url.push(path);
    url.push(encodeURIComponent(query.id));
    if (prefix) {
      url.unshift(prefix);
    }
    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }
    delete query.id;
    return url;
  }
}
