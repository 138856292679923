import Service, { inject as service } from '@ember/service';

export default class CustomizationsService extends Service {
  @service auth;  @service applicationSession;  @service store;
  get customStyle() {
    const libraryId = this.applicationSession.selectedLibrary

    // This is to prevent a "You need to pass both a model name and id to the store's peekRecord method" error
    if (!libraryId) {
      return '';
    }

    const library = this.store.peekRecord('library', libraryId);

    if (!library) {
      return '';
    }

    const link = library.httpsCustomBackgroundImageUrl || '';

    if (!link) {
      return '';
    }

    return `background-size: auto; background-image: url(${link});`;
  }
}
