import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'libkey-web/config/environment';

export class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

var browserIsCompatible = function () {
  return supportsFlexbox() || isLegacyWebview() || isAuthPage() || isConfirmEmailPage();
};

function supportsFlexbox() {
  return (window.Modernizr || {}).flexbox;
}

function isLegacyWebview() {
  return (window.Modernizr || {}).flexboxlegacy && window.navigator.userAgent.indexOf('Android 4.0') >= 0;
}

function isAuthPage() {
  return window.location.href.match('auth');
}

function isConfirmEmailPage() {
  return !!window.location.href.match('confirm-email');
}

if (config.environment === 'test' || browserIsCompatible()) {
  loadInitializers(App, config.modulePrefix);
} else {
  window.location = '/incompatible-browser.html';
}

(function () {
  if ("performance" in window === false) {
    window.performance = {};
  }

  Date.now = (Date.now || function () {  // thanks IE8
    return new Date().getTime();
  });

  if ("now" in window.performance === false) {
    var nowOffset = Date.now();

    if (performance.timing && performance.timing.navigationStart) {
      nowOffset = performance.timing.navigationStart;
    }

    window.performance.now = function () {
      return Date.now() - nowOffset;
    };
  }
})();

export default App;
