import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class ErrorController extends Controller {
  @service analytics
  @service router

  @action
  async retry() {
    await this.analytics.recordEvent({
      category: 'ArticleTryAgainTapped',
      action: window.location.href,
    });
    return this.router.transitionTo('index');
  }
}
