import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';

export default class LoginInformation extends Component {
  @service intl;

  get shouldDisplayVpnError() {
    return this.args.error && this.args.library && this.args.library.id.toString() === (this.args.error.libraryId || 0).toString();
  }

  get vpnErrorMessage() {
    const intl = this.intl;
    return intl.t('vpn_error_message', { libraryName: this.args.library.name, libraryId: this.args.library.id, ipAddress: this.args.error.requestIp });
  }

  get authenticationInfoLinkLabel() {
    const intl = this.intl;
    let label = this.args.library.webLoginInfo.linkLabel;

    if (isBlank(label)) {
      return intl.t('more_authentication_info');
    } else {
      return label;
    }
  }
}
