import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class RefreshCurrentRoute extends Helper {
  @service router;

  compute([routeName = this.router.currentRouteName]) {
    return () => {
      this.router.refresh(routeName)
    };
  }
}
