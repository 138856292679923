import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class ChooseLibraryController extends Controller {
  @service applicationSession;
  @service store;
  @service router;

  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;

  // When transitioning back to the index page,
  // just fade the left panel
  async performLeftFade() {
    this.leftPanelContentClass = "fade-out";
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  // When transitioning back to wayfless-article-in-pmid route
  // fade both panels before transitioning
  async performDoubleFade() {
    this.leftPanelContentClass = "fade-out";
    this.rightPanelCoverClass = "fade-in";
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  @action
  async confirmLibrary(selectedLibrary) {
    const applicationSession = this.applicationSession;

    if (selectedLibrary) {
      await this.performDoubleFade();
      const libraryId = selectedLibrary.id;
      applicationSession.selectedLibrary = libraryId
      applicationSession.transitioningFromChooseLibrary = true
      // Clear the store of the library
      this.store.unloadAll('library');
      // Clear the store of all other models that can have library-specific data (like whether the library has access)
      this.store.unloadAll('article');
      this.store.unloadAll('issue');
      this.store.unloadAll('journal');
      this.handleLocaleOnLibraryRoute();

      return this.router.transitionTo('wayfless-article-or-pmid', libraryId, this.requestedId);
    }
  }

  @action
  async fadeOutChooseUnaffiliatedVisitArticle() {
    const applicationSession = this.applicationSession;

    applicationSession.set('selectedLibrary', 'unaffiliated');
    applicationSession.set('transitioningFromChooseLibrary', true);
    await this.performDoubleFade();
  }

  @action
  async tryAgain() {
    await this.performLeftFade();

    this.applicationSession.set('transitioningFromChooseLibrary', true);
    this.router.transitionTo('index');
  }
}
