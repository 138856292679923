import { scheduleOnce, later } from '@ember/runloop';
import { allSettled, hash, resolve } from 'rsvp';
import EmberObject from '@ember/object';
import Mixin from '@ember/object/mixin';
import window from 'ember-window-mock';
import { isTesting, macroCondition } from '@embroider/macros';

// This mixin captures helper functions
// common to routes that
// display the article-loading-box
// component

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({

  // Takes promises that resolve to the
  // article model (with journal model and issue model icluded)
  // that we're loading and to the library's model
  //
  // Returns a promise that
  // resovles to an object
  // with all the properties the
  // article-loading-box component
  // needs
  prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary) {

    return findArticleWithJournalAndIssue.then((article) => {
      let getIssue, getJournal;

      if (!article) {
        getIssue = resolve(undefined);
        getJournal = resolve(undefined);
      } else {
        if (article.journal) {
          getJournal = resolve(article.journal);
        } else {
          getJournal = article.fixedBelongsToLoad('journal');
        }

        if(article.inPress) {
          getIssue = EmberObject.create({
            library: findLibrary,
            //TODO: Validate why article.library is missing data properties.
            //Seems like article.library is maybe returning the the unauthenticated library?
            //JIRA https://thirdiron.atlassian.net/browse/BZ-3585
            journal: getJournal,
            title: 'Articles in Press',
            date: new Date().toISOString().split('T')[0],
            year: new Date().getFullYear()
          });
        } else {
          if (article.issue) {
            getIssue = resolve(article.issue);
          } else {
            getIssue = article.fixedBelongsToLoad('issue');
          }
        }
      }

      return allSettled([findLibrary, getJournal, getIssue]).then((values) => {
        if (!article) {
          return hash({
            article: undefined,
            issue: undefined,
            library: values[0].value,
            journal: undefined
          });
        }

        const library = values[0].value,
          journal = values[1].value || article.journal,
          issue = values[2].value;


        // We have to relate the journal and issue models that came back to the selected library
        // or else our adapters won't be able to construct API URLs properly for them
        if (journal && journal.belongsTo && library) {
          const journalLibraryRef = journal.belongsTo('library');
          journalLibraryRef.push(library);
        }

        // If we have an actual issue model rather than a articles-in-press "issue"
        // set it's library relationship too
        if (issue && issue.belongsTo && library) {
          const issueLibraryRef = issue.belongsTo('library');
          issueLibraryRef.push(library);
        }

        return hash({
          article: article,
          issue: issue,
          library: library,
          journal: journal
        });
      });

   });
  },

  hasFullTextAccess(model, articleHasContentLocationLink) {
    return this.hasAccessIfHasLink(model) &&
      articleHasContentLocationLink;
  },


   hasFullTextFileAccess(model, articleHasFullTextFileLink) {
    return this.hasAccessIfHasLink(model) &&
      articleHasFullTextFileLink;
  },

   /**
   * Helper function to capture conditions common to determining if the user has full text access
   * and determining if the user has full text file access
   */
   hasAccessIfHasLink(model) {
     return !(model.journal.accessedThroughAggregator && model.article.inPress) &&
      !(!!model.journal.embargoDescription && model.article.inPress) &&
      !model.issue.embargoed &&
      !model.article.vpnRequired;
   },

  // Takes the name of a property that may
  // hold a URL to redirect to.  If set,
  // sets things up so that a redirect happens
  // shortly after this route renders
  setupRedirectAfterRender(redirectOptions) {

    var timeUntilTransition = 3000;
    if (macroCondition(isTesting())) {
      timeUntilTransition = 0;
    }

    // handle options that provide a property that holds a URL
    // to handle redirects that go out of BZWeb
    if (redirectOptions.urlPropertyName) {

      const redirectUrl = this.get(redirectOptions.urlPropertyName);

      if (redirectUrl) {
        //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
        scheduleOnce('afterRender', this, function() {
          window.location.assign(redirectUrl);
        });
      }

    }

    // For redirects that remain in BZWeb, look for transitionToArguments
    if (redirectOptions.transitionToArgumentsPropertyName) {

      const transitionToArguments = this.get(redirectOptions.transitionToArgumentsPropertyName);

      if (transitionToArguments) {
        //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
        scheduleOnce('afterRender', this, () => {
          // Transitions go so fast we need to delay a little bit so
          // the interstitial actually displays for a little bit!
          later(this, () => {
            this.transitionTo.apply(this, transitionToArguments);
          }, timeUntilTransition);
        });
      }

    }

  }

});
