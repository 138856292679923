/* eslint-disable ember/no-mixins */
import { inject as service } from '@ember/service';
import RESTAdapter from '@ember-data/adapter/rest';
import config from '../config/environment';
import BelongsToLibraryAdapter from '../mixins/belongs-to-library-adapter';
import AuthenticatingAdapter from '../mixins/authenticating-adapter';
import LocalizedAdapter from '../mixins/localized-adapter';

export default class Application extends RESTAdapter.extend(LocalizedAdapter, AuthenticatingAdapter, BelongsToLibraryAdapter) {
  @service intl
  @service auth
  @service applicationSession
  namespace = config.apiNamespace
  host = config.apiHost;

  handleResponse(status, headers, payload) {
    const unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

    if (unauthorizedError) {
      return unauthorizedError;
    }

    return super.handleResponse(...arguments);
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldReloadAll() {
    return true;
  }
}
