import { Factory, association, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  id(i) {
    return `A${i + 1}`;
  },

  title() {
    return faker.lorem.sentence();
  },

  authors() {
    return (
      faker.person.fullName() +
      ', ' +
      faker.person.fullName() +
      ', ' +
      faker.person.fullName()
    );
  },

  date() {
    return faker.date.past({ years: 3 }).toISOString().split('T')[0]; // Generates a past date within the last 10 years
  },

  startPage() {
    return `${faker.number.int({ min: 1, max: 50 })}`;
  },

  endPage() {
    return `${faker.number.int({ min: 51, max: 100 })}`;
  },

  contentLocation() {
    return faker.internet.url();
  },

  doi() {
    return `10.${faker.number.int({
      min: 1000,
      max: 9999,
    })}/${faker.lorem.slug()}`;
  },

  pmid() {
    return faker.number.int({ min: 100000, max: 999999 }).toString();
  },

  libraryIllEmail() {
    return faker.internet.email();
  },

  library: association(),
  journal: association(),
  issue: association(),

  fullText: trait({
    fullTextFile: faker.internet.url(),
  }),
});
