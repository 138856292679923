/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import window from 'ember-window-mock';
import _ from 'lodash';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';

function redirectToFallbackUrl(article) {
  const fallbackUrl = article.pubmedFallbackURL;

  if (!fallbackUrl) {
    console.log('Received no fallbackUrl, cannot fail over to library link resolver');
    return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
  }

  return window.location.replace(fallbackUrl);
}

export default class ArticlePmidFormatchooserRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service analytics;
  @service applicationSession;
  @service unpaywall;
  @service router;
  @service store;
  @service flashMessages;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = params.library_id;
    const pmid = params.pmid;
    this.requestedPmid = pmid;

    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
    analytics.setProductDimension('LibkeyLink');

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    if (!libraryToken) {
      await this.auth.authenticateLibrary({libraryId}, transition);
      await this.handleLocaleOnLibraryRoute();
      return;
    } else {
      applicationSession.set('selectedLibrary', libraryId);
      await this.handleLocaleOnLibraryRoute();
    }

    // If we got tracking info, create a GA event then remove the tracking param so we don't double report
    if (queryParams.utm_source && queryParams.utm_source.toLowerCase().includes('api_')) {
      this.router.replaceWith('article-pmid-formatchooser', libraryId, pmid, { queryParams: _.omit(queryParams, 'utm_source') });
    }
  }

  model(params, { to: { queryParams } }) {
    this.refSystem = queryParams.ref_system;

    const libraryId = params.library_id;
    const requestedPMID = params.pmid;
    const findArticleWithJournalAndIssue = this.store.queryRecord('article', { pmid: requestedPMID, reload: true, include: 'issue,journal' });
    const findLibrary = this.store.findRecord('library', libraryId);
    return all([findArticleWithJournalAndIssue, findLibrary])
      .catch((err) => {
        if (err.status === 404) {
          const pubmedFallbackURL = err.payload.errors[0].links.pubmedFallbackURL;

          if (this.disableRedirect) {
            return;
          }

          if (pubmedFallbackURL) {
            return window.location.replace(pubmedFallbackURL);
          } else {
            return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
          }

        } else {
          throw err;
        }
      })
      .then(async ([article, library]) => {
        if (!article) {
          // Do nothing. The .catch block should have already started a redirect
          return;
        }

        // Check if library has an active libkey subscription
        if (!library?.subscriptions?.libkey?.active) {
          if (this.disableRedirect) {
            return;
          }
          this.flashMessages.alert('This library is not subscribed to LibKey.');
          window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
          return;
        }

        const articleRetracted = !!article.retractionDoi;
        const articleHasExpressionOfConcern = !!article.expressionOfConcernDoi;
        const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');

        let unpaywallUrls;

        const articleIsAbandoned = !!article && !!article.abandoned;
        // we got an article, but we can't send them anywhere.
        // if we can show the format chooser, try unpaywall for urls
        // otherwise forward to the pubmedFallbackUrl
        if (!article.contentLocation && !article.fullTextFile && !articleIsAbandoned) {

          const articleDoi = article.doi;

          if (!!articleDoi &&
            (!article || !article.unpaywallDataSuppressed) &&
            (!library || library.useLiveUnpaywallCalls)) {
            unpaywallUrls = await this.unpaywall.getUnpaywallUrls(articleDoi);

            const unpaywallHasLink = unpaywallUrls.articlePDFUrl ||
              unpaywallUrls.articleLinkUrl ||
              unpaywallUrls.manuscriptArticlePDFUrl ||
              unpaywallUrls.manuscriptArticleLinkUrl;

            const shouldUseUnpaywallLink = !article ||
              !(article.avoidUnpaywallPublisherLinks && unpaywallUrls.linkHostType === 'publisher');

            if (unpaywallHasLink && shouldUseUnpaywallLink) {

              return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
                .then((model) => {
                  return {
                    ...model,
                    unpaywallUrls,
                    showRememberChoiceCheckbox: true
                  };
                });
            }

          }

          if (!(articleRetracted||articleHasExpressionOfConcern||journalIsProblematic)) {
            return redirectToFallbackUrl(article);
          }
        }

        if (articleIsAbandoned) {
          return redirectToFallbackUrl(article);
        }

        const formatPreference = this.applicationSession.formatPreference;

        const now = new Date();
        if (!formatPreference || (Date.parse(formatPreference.expiresAt) < now) || articleRetracted || articleHasExpressionOfConcern || journalIsProblematic) {
          // either no format preference in effect or the article is retracted, EOC, or has problematic journal
          // show format chooser if there are options to choose from
          return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
            .then((model) => {
              return {
                ...model,
                showRememberChoiceCheckbox: true
              };
            });
        }

        if (formatPreference.format === 'pdf' && !!article.fullTextFile) {
          return article.fixedBelongsToLoad('journal')
            .then(() => {
              this.router.transitionTo('article-full-text-file', libraryId, article.id );
              return;
            });
        }

        if (formatPreference.format === 'web-link' && !!article.contentLocation) {
          return article.fixedBelongsToLoad('journal')
            .then(() => {
              this.router.transitionTo('new-article-content-location', libraryId, article.id );
              return;
            });
        }

        return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary);
      });

  }

  afterModel(model) {
    this.hasFullTextFile = true;

    if (model.article.vpnRequired) {
      // If vpn is required we can't proceed with the redirect.  Instead let the template render
      // the interstitial
      return;
    }

  }

  setupController(controller, model) {
    controller.set('article', model.article);
    controller.set('requestedPmid', this.requestedPmid);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('hasFullTextFile', this.hasFullTextFile);
    controller.set('loadingType', 'fullTextFile');
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('accessOptionsUrl', model.article.pubmedFallbackURL);
    controller.set('unpaywallUrls', model.unpaywallUrls);
    controller.set('refSystem', this.refSystem);
  }

  @action
  loading/*transition*/() {
    return false;
  }

  @action
  error(error, transition) {
    if (error instanceof ApiSessionExpiredError || error instanceof AuthRequiredError) {
      const libraryId = this.applicationSession.selectedLibrary;
      this.handleAPIAuthDemandError(error, libraryId, transition);
    }

    if (error.status === 404) {
      const pubmedFallbackURL = error.payload.errors[0].links.pubmedFallbackURL;

      if (this.disableRedirect) {
        return;
      }

      if (pubmedFallbackURL) {
        window.location.replace(pubmedFallbackURL);
      } else {
        window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
      }
    }

    if (error.status === 401) {
      const applicationSession = this.applicationSession;
      const currentLibraryId = applicationSession.selectedLibrary;

      applicationSession.clearLibraryToken(currentLibraryId);
      this.reload();
    }
  }
}
