
/**
 * Helper function that Ember.merges an array of source objects
 * into the target
 */
export default function mergeAll(target, sources) {
  if (!sources || !sources.length) {
    return target;
  }

  Object.assign(target, sources[0]);
  return mergeAll(target, sources.slice(1));
}
