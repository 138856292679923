/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import window from 'ember-window-mock';
import _ from 'lodash';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';

function redirectToFallbackUrl(article) {
  const fallbackUrl = article.pubmedFallbackURL;

  if (!fallbackUrl) {
    console.log('Received no fallbackUrl, cannot fail over to library link resolver');
    return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
  }

  return window.location.replace(fallbackUrl);
}

export default class ArticlePmidArticlelinkexpressRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service analytics;
  @service applicationSession;
  @service unpaywall;
  @service router;
  @service store;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = params.library_id;
    const pmid = params.pmid;
    this.requestedPmid = pmid;

    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
    analytics.setProductDimension('LibkeyLink');

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    if (!libraryToken) {
      await this.auth.authenticateLibrary({libraryId}, transition);
    } else {
      applicationSession.set('selectedLibrary', libraryId);
    }

    await this.handleLocaleOnLibraryRoute();

    // If we got tracking info, create a GA event then remove the tracking param so we don't double report
    if (queryParams.utm_source && queryParams.utm_source.toLowerCase().includes('api_')) {
      this.router.replaceWith('article-pmid-pdfexpress', libraryId, pmid, { queryParams: _.omit(queryParams, 'utm_source') });
    }
  }

  model(params, transition) {
    let { to: { queryParams } } = transition;

    this.refSystem = queryParams.ref_system;

    const libraryId = params.library_id;
    const requestedPMID = params.pmid;
    const findArticleWithJournalAndIssue = this.store.queryRecord('article', { pmid: requestedPMID, reload: true, include: 'issue,journal' });
    const findLibrary = this.store.findRecord('library', libraryId);
    return all([findArticleWithJournalAndIssue, findLibrary])
      .catch((err) => {
        if (err.status === 404) {
          const pubmedFallbackURL = err.payload.errors[0].links.pubmedFallbackURL;

          if (this.disableRedirect) {
            return;
          }

          if (pubmedFallbackURL) {
            return window.location.replace(pubmedFallbackURL);
          } else {
            return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
          }

        } else {
          throw err;
        }
      })
      .then(async ([article, library]) => {
        if (!article) {
          // Do nothing. The .catch block should have already started a redirect
          return;
        }

        // If we have an article and it's retracted, EOC, or has a problematic journal we're going to show the format chooser
        const articleIsRetracted = article && !!article.retractionDoi;
        const articleHasExpressionOfConcern = article && !!article.expressionOfConcernDoi;
        const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');
        const articleIsAbandoned = !!article && !!article.abandoned;

        if ((articleIsRetracted || articleHasExpressionOfConcern || journalIsProblematic) && !articleIsAbandoned) {

          //  If we don't have any usable links we need to contact unpaywall
          if ((!article.contentLocation && !(article.fullTextFile))) {

            const requestedDoi = article.doi;

            if (requestedDoi &&
              (!article || !article.unpaywallDataSuppressed) &&
              (!library || library.useLiveUnpaywallCalls)) {

              const unpaywallUrls = await this.unpaywall.getUnpaywallUrls(requestedDoi);
              const unpaywallHasLink = unpaywallUrls.articlePDFUrl ||
                unpaywallUrls.articleLinkUrl ||
                unpaywallUrls.manuscriptArticlePDFUrl ||
                unpaywallUrls.manuscriptArticleLinkUrl;

              const shouldUseUnpaywallLink = !(article.avoidUnpaywallPublisherLinks && unpaywallUrls.linkHostType === 'publisher');

              if (unpaywallHasLink && shouldUseUnpaywallLink) {

                return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
                 .then((model) => {
                   return {
                     ...model,
                     unpaywallUrls,
                     showRememberChoiceCheckbox: false
                   };
                 });
              }

            }
          }

          return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
            .then((model) => {
              return {
                ...model,
                showRememberChoiceCheckbox: false
              };
            });
        }

        // Check if library has an active libkey subscription
        if (!library?.subscriptions?.libkey?.active) {
          if (this.disableRedirect) {
            return;
          }
          window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
          return;
        }

        if (articleIsAbandoned) {
          return redirectToFallbackUrl(article);
        }

        if (!article.contentLocation) {
          return redirectToFallbackUrl(article);
        }

        return article.fixedBelongsToLoad('journal')
          .then(() => {
            // transition to content-location route
            return this.router.transitionTo('new-article-content-location', libraryId, article.id);
          });

      });

  }

  afterModel(model) {
    this.hasFullTextFile = true;

    if (model.article.vpnRequired) {
      // If vpn is required we can't proceed with the redirect.  Instead let the template render
      // the interstitial
      return;
    }

  }

  setupController(controller, model) {
    if (!model) {
      controller.set('showNothing', true);
      return;
    }

    controller.set('article', model.article);
    controller.set('requestedPmid', this.requestedPmid);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('hasFullTextFile', this.hasFullTextFile);
    controller.set('loadingType', 'fullTextFile');
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('accessOptionsUrl', model.article.pubmedFallbackURL);
    controller.set('unpaywallUrls', model.unpaywallUrls);
    controller.set('refSystem', this.refSystem);
  }

  @action
  loading/*transition*/() {
    return false;
  }

  @action
  error(error, transition) {
    if (error instanceof ApiSessionExpiredError || error instanceof AuthRequiredError) {
      const libraryId = this.applicationSession.selectedLibrary;
      this.handleAPIAuthDemandError(error, libraryId, transition);
    }

    if (error.status === 404) {
      const pubmedFallbackURL = error.payload.errors[0].links.pubmedFallbackURL;

      if (this.disableRedirect) {
        return;
      }

      if (pubmedFallbackURL) {
        window.location.replace(pubmedFallbackURL);
      } else {
        window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
      }
    }

    if (error.status === 401) {
      const applicationSession = this.applicationSession;
      const currentLibraryId = applicationSession.selectedLibrary;

      applicationSession.clearLibraryToken(currentLibraryId);
      this.reload();
    }
  }
}
