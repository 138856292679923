import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';

import interpretError from 'libkey-web/utils/interpret-error';

/**
 * Use this mixin to setup ajaxOptions to send
 * over API credentials for the selected library
 * with the request.
 */

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  auth: service(),
  applicationSession: service(),
  flashMessages: service(),

  ajaxOptions() {
    let options = this._super(...arguments);
    let originalBeforeSend = options.beforeSend;

    options.beforeSend = (xhr) => {
      const selectedLibraryId = this.applicationSession.selectedLibrary;
      if (selectedLibraryId && selectedLibraryId !== 'unaffiliated') {

        const authHeader = this.auth.getAPIAuthHeaderForLibrary(selectedLibraryId);

        if (authHeader) {
          xhr.setRequestHeader('Authorization', authHeader);
        }
      }

      if (originalBeforeSend) {
        originalBeforeSend(xhr);
      }
    };

    return options;

  },

  /**
   * Helper to be called from handleResponse method of adapters
   * to provide standardized interpretation of 401 & 403 responses
   *
   * returns custom error on responses it interprets, otherwise nothing
   */
  handleUnauthorizedResponse(status, headers, payload) {
    if (status === 401) {
      return interpretError(status, payload);
    } else if (status === 403) {
      const auth = this.auth;
      const applicationSession = this.applicationSession;
      const loggedInUser = applicationSession.loggedInUser;
      const token = (loggedInUser || {}).token;
      run(() => {
        auth.authenticateUser(null, null, token);
        this.flashMessages.add({
          message: '',
          type: 'alert',
          componentName: 'permissions-error-alert',
          sticky: true,
        });
      });
      return new Error('invalid permissions');
    }
  },

});
