import { Factory, association } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  id(i) {
    return `${i + 1}`;
  },

  title() {
    return faker.company.name();
  },

  available() {
    return faker.datatype.boolean();
  },

  accessedThroughAggregator() {
    return faker.datatype.boolean();
  },

  coverURL() {
    return faker.image.url();
  },

  issn() {
    return faker.datatype.boolean() ? faker.string.alphanumeric(8) : '';
  },

  eissn() {
    return faker.datatype.boolean() ? faker.string.alphanumeric(8) : '';
  },

  library: association(),
});
