import Mixin from '@ember/object/mixin';

/**
 * Use this mixin to pull in logic that modifies how API URL's are built
 * so that we request the appropriate library's view of the model
 * by prefixing the URL Ember builds with a path segment
 * of /libraries/:library_id
 */

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  buildURL: function(type, id, snapshot) {
    var originalUrl = this._super(type, id, snapshot);
    var belongsToLibrary;

    try {
      belongsToLibrary = snapshot.belongsTo('library', {id: true});
    } catch(ignore) {}  /* despite what the docs say, this method thows an exception
                           if you look up a relationship that doesn't exist, however
                           in our case it's ok if we don't have a relationship to a library. */

    if (!belongsToLibrary || type === 'article') {
      //this model doesn't "belong to" a library, so we won't mess with the URL
      return originalUrl;
    }

    var prefix = this.urlPrefix();
    if (prefix.indexOf('/') < 0) {
      //an absolute prefix, this happens when we test with pretender.
      prefix = '/' + prefix;
    }
    var suffix = originalUrl.replace(prefix, '');

    return prefix + "/libraries/" + belongsToLibrary + suffix;
  },

});
