import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { assert } from '@ember/debug';
export default class HandleRequestPanel extends Component {
  @service analytics;
  @service store;

  constructor() {
    super(...arguments);

    assert("@fulfillmentRequest is required", this.args.fulfillmentRequest);
    assert("@article is required", this.args.article);
    assert("@library (lender library) is required", this.args.library);
    assert("@requesterLibrary is required", this.args.requesterLibrary);
    assert("@journal is required", this.args.journal);
    assert("@issue is required", this.args.issue);

    if (this.args.fulfillmentRequest.status == "complete") {
      this.currentWorkflowStep = this.STEPS.FINISH;
    } else if (this.args.fulfillmentRequest.status == "declined") {
      this.currentWorkflowStep = this.STEPS.FINISH;
    }
  }

  STEPS = {
    DOWNLOAD_ARTICLE: "Download Article",
    SEND_ARTICLE: "Send Article",
    MARK_COMPLETE: "Mark Complete",
    DECLINE_REQUEST: "Decline Request",
    FINISH: "Finish",
  };

  @tracked previousWorkflowStep;
  @tracked currentWorkflowStep = this.STEPS.DOWNLOAD_ARTICLE;

  get requestHeaderTitle() {
    if (this.args.fulfillmentRequest.status == "complete") {
      return "Request Fulfilled";
    } else if (this.args.fulfillmentRequest.status == "declined") {
      return "Request Declined";
    } else {
      return "Fulfillment Request";
    }
  }

  get completedOn() {
    if (
      this.args.fulfillmentRequest.lastUpdated &&
      this.currentWorkflowStep === this.STEPS.FINISH
    ) {
      return new Intl.DateTimeFormat(
        Intl.DateTimeFormat().resolvedOptions().locale,
        {
          dateStyle: "short",
          timeStyle: "long",
        }
      ).format(new Date(this.args.fulfillmentRequest.lastUpdated));
    } else {
      return false;
    }
  }

  async recordAnalyticsEvent(eventCategory) {
    const article = this.args.article;
    const doi = article.doi;
    const journalName = this.args.journal.title;
    const issn = this.args.journal.issn;
    const eissn = this.args.journal.eissn;
    const openAccess = article.openAccess;
    const articleYear = article.articleYear;
    const issue = this.args.issue;
    const issueYear = issue.year;
    const authors = article.authors;
    const fulfillmentRequest = this.args.fulfillmentRequest;
    const created = fulfillmentRequest.created;
    const lastUpdated = fulfillmentRequest.lastUpdated;
    const millisSinceCreated = new Date(lastUpdated) - new Date(created);
    const hoursSinceCreated = Math.round(millisSinceCreated / (1000 * 60 * 60 ))
    const event = {
      category: eventCategory,
      fulfillmentRequestId: fulfillmentRequest.id,
      referenceNumber: fulfillmentRequest.customReference,
      declinedReason: fulfillmentRequest.declinedReason,
      hoursSinceCreated,
      articleId: fulfillmentRequest.articleId,
      doi,
      openAccess,
      authors,
      articleYear,
      issueYear,
      journalName,
      issn,
      eissn,
      lendingLibraryId: fulfillmentRequest.lenderLibraryId,
      lendingLibraryName: this.args.library.name,
      requestingLibraryId: fulfillmentRequest.requesterLibraryId,
      requesterEmail: fulfillmentRequest.requesterEmail,
      requestingLibraryName: this.args.requesterLibrary.name,
      libraryGroupId: fulfillmentRequest.libraryGroupId,
    };
    try {
      await this.analytics.recordEvent(event);
    } catch (err) {
      console.log(`Error in recordEvent(${JSON.stringify(event)}): ${err.message||err.stack}`);
    }
  }

  @action
  async onDeclineRequest() {
    this.transitionToStep(this.STEPS.FINISH);
    await this.recordAnalyticsEvent("FulfillmentRequestDeclined");
  }

  @action
  async onCompleteRequest() {
    this.transitionToStep(this.STEPS.FINISH);
    await this.recordAnalyticsEvent("FulfillmentRequestComplete");
  }

  @action
  transitionToStep(step) {
    this.previousWorkflowStep =
      this.currentWorkflowStep || this.STEPS.DOWNLOAD_ARTICLE;
    this.currentWorkflowStep = step || this.STEPS.DOWNLOAD_ARTICLE;
  }
}
