import Modifier from "ember-modifier";
import { inject as service } from "@ember/service";
import { assert } from "@ember/debug";
import { registerDestructor } from "@ember/destroyable";

function cleanup(instance) {
  instance?.childView?.removeEventListener(
    "click",
    instance.sendAnalyticsEvent
  );
}

export default class TracksClicksModifier extends Modifier {
  @service analytics;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element, positional, named) {
    if (!this.element) {
      this.element = element;
    }

    cleanup(this);

    this.category = named.category;
    this.trackedElement = named.trackedElement;
    this.action = named.action;
    this.label = named.label;
    this.value = named.value;
    this.childView = element;

    if (this.trackedElement) {
      this.childView = element.querySelector(this.trackedElement);
    }

    assert("track-clicks: category is required", this.category);
    assert("track-clicks: no target element found", this.childView);

    this.childView.addEventListener("click", () => {
      this.sendAnalyticsEvent();
    });
  }

  sendAnalyticsEvent() {
    this.analytics.trackEvent({
      category: this.category,
      label: this.label,
      action: this.action,
      value: this.value,
    });
  }
}
