import { Promise } from 'rsvp';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';

import _ from 'lodash';

function responseHasErrorsInTopLevelArray(errorResponse) {
  return Array.isArray(errorResponse.responseJSON);
}

function isJsonApi10ErrorResponse(errorResponse) {
  return errorResponse.responseJSON &&
    errorResponse.responseJSON.errors &&
    Array.isArray(errorResponse.responseJSON.errors);
}

// Extends a route to have a method that
// checks if a library has been chosen.
// If not, sends the user through the WAYF
// configured to come back to the route
// after a library has been selected and
// authenticated with

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({

  analytics: service(),
  auth: service(),
  applicationSession: service(),


  ensureLibrarySelected(transition, wayfParameter) {
    const applicationSession = this.applicationSession;
    const auth = this.auth;
    const forwardToWAYF = (transition) => {
      applicationSession.set('transitionToRetryAfterAuth', transition);
      this.transitionTo('wayf', wayfParameter);
    };

    let libraryId = applicationSession.selectedLibrary;

    if (libraryId) {
      return Promise.resolve()
        .then(() => {
          if (!applicationSession.lookupLibraryToken(libraryId)) {
            return auth.authenticateLibrary({ libraryId }, transition);
          }
        })
        .then(() => {
          return Promise.resolve(libraryId);
        });
    }

    // no library selected, first check if there's recent library
    // stored.
    let mostRecentLibrary = applicationSession.mostRecentLibrary;

    if (mostRecentLibrary) {
      applicationSession.set('selectedLibrary', mostRecentLibrary);

      return Promise.resolve()
        .then(() => {
          if (!applicationSession.lookupLibraryToken(libraryId)) {
            return auth.authenticateLibrary({ libraryId }, transition);
          }
        })
        .then(() => {
          return Promise.resolve(mostRecentLibrary);
        });
    }

    // Then try detecting library by IP
    return this.store.query('library', {detect: 'ip', subscription: 'bzweb'})
      .then(async (libraries) => {
        if (!libraries.length) {
          return forwardToWAYF(transition);
        } else {

          const library = libraries.firstObject;

          await this.analytics.recordEvent({
            category: "userJoinedLibrary",
            action: "IPDetected",
            label: library.name,
            value: library.id
          });

          // We know that our IP is within the library's ranges, so we can safely
          // perform direct-only auth
          return auth.authenticateLibraryDirectOnly(library.id)
            .then(() => {
              return transition.retry();
            },
            () => {
              // Authentication really shouldn't fail if we detected the library by IP, but if it somehow does forward them to the WAYF
              // They're either have better luck or at least will probably give a more useful error report than quietly blowing up here.
              // TODO: Send message to error reporter, this code path should only happen if there
              // is a network error or the back-end is down or else we have a bug.
              forwardToWAYF(transition);
            });
        }
      });

  },

  /**
   * Standardized handler for when API calls come back demanding an auth credential
   * either because the submitted one has expired, or one was missing altogether
   *
   * Intended to be called from the error hook of routes
   *
   * @param {Error} err - The error object representing the error
   * @param {String} libraryId - The id of the library that owns the data we need a credential authorizing access to
   * @param {Object} transition - The transition object representing the transition that was in progress when the error happened
   *
   */
  handleAPIAuthDemandError(err, libraryId, transition) {
    const sessionHasExpired = err instanceof ApiSessionExpiredError;

    if (sessionHasExpired || err instanceof AuthRequiredError) {
      return this.auth.attemptDirectLibraryAuth(libraryId, false, transition)
        .then(() => {
          return transition.retry();
        }, (err) => {
          /*
           * A 403 indicates the request for a token was rejected.  If preproxy
           * is present, we can retry by routing a request through the proxy.
           * If ipRangeError is present, that means the library has a VPN, so
           * we can instruct the user to connect to it and then try token retrieval
           * again.  Absence of both means we have no means to get a token
           * so all we can do is explain to the user that BrowZine is not available.
           */

          function responseHasAuthErrorAndNoAuthOptions(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard format until made consistent

            function errorIsAuthError(errorObject) {
              return errorObject.status === '403' &&
                errorObject.title === 'Authentication Failed';
            }

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errors = errorResponse.responseJSON;
              return errors.some((error) => errorIsAuthError(error)) &&
                !responseHasAuthErrorWithVPNAvailable(errorResponse) &&
                !responseHasAuthErrorWithProxyAvailable(errorResponse) &&
                !responseHasAuthErrorWithSSOGateway(errorResponse);

            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errors = errorResponse.responseJSON.errors;
              return errors.some((error) => errorIsAuthError(error)) &&
                !responseHasAuthErrorWithVPNAvailable(errorResponse) &&
                !responseHasAuthErrorWithProxyAvailable(errorResponse) &&
                !responseHasAuthErrorWithSSOGateway(errorResponse);
            }

            return err.responseJSON &&
              err.responseJSON.status === '403' &&
              err.responseJSON.title === 'Authentication Failed' &&
              !err.responseJSON.preproxy &&
              !err.responseJSON.ipRangeError &&
              !err.responseJSON.ssoGateway;
          }

          // This is kind of obtuse, but the API token endpoint
          // indicates there's a VPN by incuding an ipRangeError
          // property on the response
          function responseHasAuthErrorWithVPNAvailable(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard format until made consistent

            function errorIsAuthErrorWithVPNAvailable(errorObject) {
              return errorObject.status === '403' &&
                errorObject.title === 'Authentication Failed' &&
                !!errorObject.ipRangeError;
            }

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errors = errorResponse.responseJSON;
              return errors.some((error) => errorIsAuthErrorWithVPNAvailable(error));
            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errors = errorResponse.responseJSON.errors;
              return errors.some((error) => errorIsAuthErrorWithVPNAvailable(error));
            }

            return err.responseJSON &&
              errorIsAuthErrorWithVPNAvailable(err.responseJSON);
          }

          function errorIsAuthErrorWithProxyAvailable(errorObject) {
            return errorObject.status === '403' &&
              errorObject.title === 'Authentication Failed' &&
              !!errorObject.preproxy;
          }


          function responseHasAuthErrorWithProxyAvailable(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard format until made consistent

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errors = errorResponse.responseJSON;
              return errors.some((error) => errorIsAuthErrorWithProxyAvailable(error));
            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errors = errorResponse.responseJSON.errors;
              return errors.some((error) => errorIsAuthErrorWithProxyAvailable(error));
            }

            return err.responseJSON &&
              errorIsAuthErrorWithProxyAvailable(err.responseJSON);
          }


          function getPreproxyFromErrorResponse(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard formats until made constistent

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errorWithPreproxy = _.find(errorResponse.responseJSON, errorIsAuthErrorWithProxyAvailable);

              return errorWithPreproxy.preproxy;
            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errorWithPreproxy = _.find(errorResponse.responseJSON.errors, errorIsAuthErrorWithProxyAvailable);

              return errorWithPreproxy.preproxy;
            }

            return errorResponse.responseJSON.preproxy;
          }

          function errorIsAuthErrorWithSSOGateway(errorObject) {
            return errorObject.status === '403' &&
              errorObject.title === 'Authentication Failed' &&
              !!errorObject.ssoGateway;
          }

          function responseHasAuthErrorWithSSOGateway(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard format until made consistent

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errors = errorResponse.responseJSON;
              return errors.some((error) => errorIsAuthErrorWithSSOGateway(error));
            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errors = errorResponse.responseJSON.errors;
              return errors.some((error) => errorIsAuthErrorWithSSOGateway(error));
            }

            return err.responseJSON &&
              errorIsAuthErrorWithSSOGateway(err.responseJSON);
          }

          function getSSOGatewayFromErrorResponse(errorResponse) {
            // TODO: BZ-4103 Handle both JSON API 1.0
            // and non-standard formats until made constistent

            if (responseHasErrorsInTopLevelArray(errorResponse)) {
              const errorWithSSOGateway = _.find(errorResponse.responseJSON, errorIsAuthErrorWithSSOGateway);

              return errorWithSSOGateway.ssoGateway;
            }

            if (isJsonApi10ErrorResponse(errorResponse)) {
              const errorWithSSOGateway = _.find(errorResponse.responseJSON.errors, errorIsAuthErrorWithSSOGateway);

              return errorWithSSOGateway.ssoGateway;
            }

            return errorResponse.responseJSON.ssoGateway;
          }


          if (err.name === 'TransitionAborted') {
            // These are expected as part of the retry (I think).  Don't need to do anything on them
            return;
          }

          if (responseHasAuthErrorAndNoAuthOptions(err)) {
            return this.transitionTo('library.unavailable', libraryId, {queryParams: {context: sessionHasExpired ? 'Expired Session' : undefined}});

          } else if (responseHasAuthErrorWithVPNAvailable(err)) {
            // Save what we were trying to do and send the user to a route instructing
            // them to connect to their VPN
            this.applicationSession.set('transitionToRetryAfterAuth', transition);
            return this.transitionTo('library.vpn-required', libraryId, {queryParams: {context: sessionHasExpired ? 'Expired Session' : undefined}});
          } else if (responseHasAuthErrorWithSSOGateway(err)) {
            // The direct auth attempt should have encoded the original transition into the success path send
            // to the API enpoint, so it should be encoded in the "preproxy" URL sent back  by the API so that
            // after proxy authentication we can decode it and send the user to whatever they were originally
            // trying to do.
            const ssoGateway = getSSOGatewayFromErrorResponse(err);
            return this.auth.authenticateLibraryThroughSSOGateway(ssoGateway, transition);
          } else if (responseHasAuthErrorWithProxyAvailable(err)) {
            // The direct auth attempt should have encoded the original transition into the success path send
            // to the API enpoint, so it should be encoded in the "preproxy" URL sent back  by the API so that
            // after proxy authentication we can decode it and send the user to whatever they were originally
            // trying to do.
            const preproxyUrl = getPreproxyFromErrorResponse(err);
            return this.auth.authenticateLibraryThroughProxy(preproxyUrl, transition);
          }

          // Something like a 503 on the tokens endpoint would fall down to here.
          // TODO: BZ-4104
          // The above branches should exhaust the possibilities
          // log a warning if the code actually reaches here
          console.warn(`
          Direct auth failed for unknown reason, could not detect how to handle failure
          error: ${err.stack}`);
      });
    }
  }
});
