import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  id() {
    return `${
      faker.date.recent().toISOString().split('T')[0]
    }-${faker.string.alphanumeric(8)}`;
  },

  created() {
    return faker.date.past().toISOString();
  },

  status() {
    return faker.helpers.arrayElement(['pending', 'rejected', 'complete']);
  },

  articleId() {
    return `A${faker.number.int({ min: 1, max: 10 })}`; // Assuming 10 articles
  },

  requesterLibraryId() {
    return `auto-gen-lib-${faker.number.int({ min: 1, max: 10 })}`; // Assuming 10 libraries
  },

  requesterEmail() {
    return faker.internet.email();
  },

  lenderLibraryId() {
    return `auto-gen-lib-${faker.number.int({ min: 1, max: 10 })}`; // Assuming 10 libraries
  },

  libraryGroupId() {
    return `${faker.number.int({ min: 100, max: 999 })}`;
  },

  customReference() {
    return `yam-${faker.string.alphanumeric(6)}`;
  },

  pending: trait({
    status: 'pending',
  }),

  complete: trait({
    lastUpdated: faker.date.recent().toISOString(),
    status: 'complete',
  }),

  declined: trait({
    lastUpdated: faker.date.recent().toISOString(),
    status: 'declined',
  }),
});
