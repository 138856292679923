import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

export default class Library extends JSONAPISerializer {
  attrs = {
    rawLogo: 'logo'
  }

  keyForAttribute(key) { return key; }

  keyForRelationship(rawKey) {
    return camelize(rawKey);
  }
}
