import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import articlePages from "libkey-web/utils/article-pages";
import { assert } from "@ember/debug";

export default class HandleRequestPanelSendArticleComponent extends Component {
  @service store;

  constructor() {
    super(...arguments);
    assert(
      "@requestingLibraryName argument is required",
      this.args.requestingLibraryName
    );
    assert(
      "@lenderLibraryEmail argument is required",
      this.args.lenderLibraryEmail
    );
    assert(
      "@fulfillmentRequest argument is required",
      this.args.fulfillmentRequest
    );
    assert("@article argument is required", this.args.article);
    assert("@journal argument is required", this.args.journal);
    assert("@issue argument is required", this.args.issue);
  }

  get emailBody() {
    return encodeURIComponent(`Dear ${this.args.requestingLibraryName},

      Regarding your article request ID ${
        this.args.fulfillmentRequest.id
      }, Reference Number: ${this.args.fulfillmentRequest.customReference}, for:

      ${this.args.article?.title}
      ${this.args.journal?.title}
      ${this.args.article?.authors}
      ${this.args.issue?.title}, ${articlePages(this.args.article)}, ${
      this.args.issue?.year
    }.
      ${this.args.article?.doi}

      The PDF is attached.

      If you need to contact us directly, our email address is ${
        this.args.lenderLibraryEmail
      }

      Thank you for using INCDocs!

      --------

      Need Support? Please log a call on the national service desk at https://support.library.hee.nhs.uk/support/home
    `);
  }

  get emailSubject() {
    return `INCDocs: Supplied Request: ${this.args.fulfillmentRequest?.customReference}`;
  }
}
