/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { scheduleOnce, later } from '@ember/runloop';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import window from 'ember-window-mock';
import _ from 'lodash';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';
import { macroCondition, isTesting } from '@embroider/macros';

export default class NewArticleContentLocationRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service analytics;
  @service applicationSession;
  @service router;
  @service store;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    const libraryId = params.library_id;
    const articleId = params.article_id;

    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route

    await this.handleLocaleOnLibraryRoute();

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    // If we got tracking info, create a GA event then remove the tracking param so we don't double report
    if (queryParams.utm_source && queryParams.utm_source.toLowerCase().includes('api_')) {
      await this.analytics.recordEvent({
        category: 'PublicAPIContentLocation',
        action: articleId
      });

      analytics.setProductDimension('LibkeyAPI');

      this.router.replaceWith('new-article-content-location', libraryId, articleId, { queryParams: _.omit(queryParams, 'utm_source') });
    } else if (queryParams.utm_source && queryParams.utm_source.toLowerCase() === 'nomad') {
      analytics.setProductDimension('LibkeyNomad');
    } else {
      if (!analytics.getProductDimension()) {
        analytics.setProductDimension('LibkeyIO');
      }
    }

    if (!libraryToken && libraryId !== 'unaffiliated') {
      return this.auth.authenticateLibrary({libraryId}, transition);
    } else {
      applicationSession.set('selectedLibrary', libraryId);
      return;
    }

  }

  model(params) {
    const libraryId = params.library_id;
    const findArticleWithJournalAndIssue = this.store.findRecord('article', params.article_id, { reload: true, include: 'issue,journal' });
    let findLibrary;
    if (libraryId !== 'unaffiliated') {
      findLibrary = this.store.findRecord('library', libraryId);
    }

    return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary);
  }

  async afterModel(model, transition) {
    let { to: { params, queryParams } } = transition;

    const journalId = model.journal.id;
    const articleId = params.article_id;
    const applicationSession = this.applicationSession;
    const currentLibraryId = applicationSession.selectedLibrary;
    const contentLocation = model.article.contentLocation;
    var timeUntilTransition = 100;
    if (macroCondition(isTesting())) {
      timeUntilTransition = 0;
    }


    if (!contentLocation) {
      // article is not available.  Return before recording the
      // ArticleDownloadSuccess event so the interstitial can render
      // with an error message
      return;
    }

    this.hasArticle = true;

    const redirectQueryParams = [];

    if (queryParams.ref_system) {
      redirectQueryParams.push(`ref_system=${queryParams.ref_system}`);
    }

    const libraryToken = (applicationSession.lookupLibraryToken(currentLibraryId) || {}).token;

    if (libraryToken) {
      redirectQueryParams.push(`access_token=${libraryToken}`);
    }

    const redirectQueryString = redirectQueryParams.join('&');
    const redirectUrl = redirectQueryString ?
      `${contentLocation}?${redirectQueryString}` :
      contentLocation;

    if (model.article.vpnRequired) {
      // If vpn is required we can't proceed with the redirect.  Instead let the template render
      // the interstitial
      return;
    }

    await this.analytics.recordEvent({
      category: 'ArticleDownloadSuccess',
      action: articleId,
      label: model.journal.title,
      value: journalId,
      openAccess: model.article.openAccess,
      withinLibraryHoldings: model.article.withinLibraryHoldings,
    });

    if (queryParams.disableRedirect) {
      return;
    }

    //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      later(this, () => {
        window.location.replace(redirectUrl);
      }, timeUntilTransition);
    });
  }

  setupController(controller, model) {
    controller.set('article', model.article);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('hasArticle', this.hasArticle);
    controller.set('loadingType', 'fullText');
  }

  @action
  loading/*transition*/() {
    // Don't let the loading bubble up to the application
    // route to prevent it from setting the background color
    return false;
  }
}
