/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import window from 'ember-window-mock';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';
import OpenurlRoute from '../mixins/openurl-route';
import 'url-search-params-polyfill';

export default class ArticleOpenurlPdfexpressRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute, OpenurlRoute) {
  @service analytics;
  @service applicationSession;
  @service router;
  @service unpaywall;
  @service store;
  @service flashMessages;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = params.library_id;
    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
    analytics.setProductDimension('LibkeyLink');

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    if (!libraryToken) {
      await this.auth.authenticateLibrary({libraryId}, transition);
    } else {
      applicationSession.set('selectedLibrary', libraryId);
    }

    await this.handleLocaleOnLibraryRoute();
  }

  getRedirectUrl(linkResolverUrlBase) {
    const urlBase = linkResolverUrlBase;
    if (!urlBase) {
      return '';
    }

    const urlBaseQuerystring = urlBase.split('?')[1];
    const urlBaseBeforeQuerystring = urlBase.split('?')[0];

    const originalQuerystring = this.getQuerystring();

    if (originalQuerystring && urlBaseQuerystring) {
      return `${urlBaseBeforeQuerystring}?${originalQuerystring}&${urlBaseQuerystring}`;
    } else if (originalQuerystring) {
      return `${urlBaseBeforeQuerystring}?${originalQuerystring}`;
    } else if (urlBaseQuerystring) {
      return `${urlBaseBeforeQuerystring}?${urlBaseQuerystring}`;
    } else {
      return urlBaseBeforeQuerystring;
    }

  }

  model(params, transition) {

    function getArticle() {
      if (doi) {
        this.requestedId = doi;
        const findArticleByDOI = this.store
          .queryRecord("article", {
            doi: doi,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              return;
            }
            throw err;
          });

        return findArticleByDOI;
      }

      // No DOI found? check for a PMID

      if (pmid) {
        this.requestedId = pmid;
        const findArticleByPmid = this.store
          .queryRecord("article", {
            pmid,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              return;
            }
            throw err;
          });

        return findArticleByPmid;
      }

      // NO DOI or PMID, so we can't even try to fetch an article
      return;
    }

    const doi = this.checkQueryParamsForDOI(transition);
    const pmid = this.checkQueryParamsForPmid(transition);

    const libraryId = params.library_id;
    const findLibrary = this.store.findRecord('library', libraryId);
    const findArticle = getArticle.bind(this)(doi, pmid);

    return all([findArticle, findLibrary])
      .then(async ([article, library]) => {

        if (!library.linkResolverUrlBase) {
          console.log('Library had no linkResolverBaseURL, cannot fail over to library link resolver');
          return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
        }

        const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');

        // If we have an article and it's retracted, EOC, or has a problematic journal we're going to show the format chooser
        if (article && (!!article.retractionDoi || !!article.expressionOfConcernDoi || journalIsProblematic)) {
          //  If we don't have any usable links we need to contact unpaywall
          if ((!article.contentLocation && !(article.fullTextFile))) {

            const requestedDoi = (article ? article.doi : doi) || doi;

            if (requestedDoi &&
              (!article.unpaywallDataSuppressed) &&
              (!library || library.useLiveUnpaywallCalls)) {

              const unpaywallUrls = await this.unpaywall.getUnpaywallUrls(requestedDoi);
              const unpaywallHasLink = unpaywallUrls.articlePDFUrl ||
                unpaywallUrls.articleLinkUrl ||
                unpaywallUrls.manuscriptArticlePDFUrl ||
                unpaywallUrls.manuscriptArticleLinkUrl;
              const shouldUseUnpaywallLink = !(article.avoidUnpaywallPublisherLinks && unpaywallUrls.linkHostType === 'publisher');

              if (unpaywallHasLink && shouldUseUnpaywallLink) {

                return this.prepareArticleLoadingBoxModel(findArticle, findLibrary)
                 .then((model) => {
                   return {
                     ...model,
                     unpaywallUrls,
                     showRememberChoiceCheckbox: false
                   };
                 });
              }

            }
          }

          return this.prepareArticleLoadingBoxModel(findArticle, findLibrary)
            .then((model) => {
              return {
                ...model,
                showRememberChoiceCheckbox: false
              };
            });
        }

        // couldn't retrieve any article, or we got an article, but we can't send them anywhere.
        // if we have a doi on hand, use it to check unpaywall for urls.  If we can't get
        // unpaywall urls, forward to the library's link resolver
        if (!article || (!article.contentLocation && !(article.fullTextFile))) {

          window.location.replace(this.getRedirectUrl(library.linkResolverUrlBase));
          return;
        }


        if (!article.fullTextFile) {
          window.location.replace(this.getRedirectUrl(library.linkResolverUrlBase));
          return;
        }

        // transition to pdf route
        this.router.transitionTo('article-full-text-file', libraryId, article.id );
        return;

      });
  }

  afterModel(model) {
    if (!model) return;

    if (!model.library?.subscriptions?.libkey?.active) {
      if (this.disableRedirect) {
        return;
      }
      this.flashMessages.alert('This library is not subscribed to LibKey.');
      window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
      return;
    }

  }

  setupController(controller, model) {
    if (!model) {
      controller.set('showNothing', true);
      return;
    }

    controller.set('article', model.article);
    controller.set('requestedId', this.requestedId);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('unpaywallUrls', model.unpaywallUrls);
    controller.set('accessOptionsUrl', this.getRedirectUrl(model.library.linkResolverUrlBase));
  }

  getQuerystring() {
    const router = this.router;
    if (!router.location || !router.location.path) {
      // Prefer the router's take on the location in case we're dealing with a transition from another
      // ember route in which case the value in the location bar would be out of date to be replaced by
      // the value from the router, but sometimes the router doesn't have any location.path property.  In that
      // case fall back to the what the window says the location bar says.
      return `${window.location.href.split('?')[1]}`;
    }
    const queryString = router.location.path.split('?')[1];
    return queryString;
  }

  @action
  loading/*transition*/() {
    return false;
  }
}


