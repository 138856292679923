import { action } from '@ember/object';
import Component from '@glimmer/component';
import window from 'ember-window-mock';

export default class ArticleLoadingUnavailableArticleHelp extends Component {
  @action
  gotoILL(article) {
    window.location.assign(article.ILLURL);
  }
}
