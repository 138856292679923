/* eslint-disable ember/no-mixins */
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from 'libkey-web/config/environment';
import AuthenticatingAdapter from "../mixins/authenticating-adapter";

export default class FulfillmentRequest extends JSONAPIAdapter.extend(AuthenticatingAdapter) {
  namespace = config.apiNamespace;
  host = config.apiHost;

  handleResponse(status, headers, payload) {
    const unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);
    if (unauthorizedError) {
      return unauthorizedError;
    }
    return super.handleResponse(...arguments);
  }
}
