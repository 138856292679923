import config from 'libkey-web/config/environment';

export function initialize(application) {
  let offline = application.lookup('service:offline');

  //Note: this sends HEAD requests
  offline.offline.options.checks = {
    xhr: {
      url: config.apiHost
    }
  };
}

export default {
  name: 'configure-offline',
  initialize: initialize
};
