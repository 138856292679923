import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

export default class HelpLink extends Component {
  @service errorReporter;

  elementId = guidFor(this);

  get label() {
    return this.args.label || 'Visit Third Iron Support';
  }

  didInsert() {
    const errorDetails = this.args.errorDetails;

    if(errorDetails) {
      this.errorReporter.reportHelpError(errorDetails);
    }
  }

  get linkTrackingSelector() {
    return `#${this.elementId} > a`;
  }

  get currentUrl() {
    return window.location.href;
  }
}
