import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class ErrorScreen extends Component {
  @service errorReporter

  @action
  reportError() {
    const error = this.args.error;
    this.errorReporter.reportUnknownError('hitFailBook', error);
  }
}
