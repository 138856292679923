import { helper } from "@ember/component/helper";
import { makeArray } from "@ember/array";
import _ from "lodash";

export default helper(function productMatch(positional /*, named*/) {
  let productName = positional[0];
  let productMatches = _.flatten(makeArray(positional[1]));

  return productMatches
    .map((p) => p.toLowerCase())
    .includes(productName.toLowerCase());
});
