import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

export default class LibraryGroup extends JSONAPISerializer {
  keyForAttribute(key) {
    return key;
  }

  keyForRelationship(rawKey) {
    return camelize(rawKey);
  }
}
