import Service, { inject as service } from '@ember/service';
import config from 'libkey-web/config/environment';
import fetch from 'fetch';
import timeoutPromise from 'libkey-web/utils/timeout-promise';
import datePartsToISODate from 'libkey-web/utils/date-parts-to-iso-date';
const API_BASE = config.apiBaseUrl;

/* This complex parsing logic should be extracted into individual utilities that can have tests */

function sortAuthors(a, b) {
  if (a.sequence === 'first') {
    return -1;
  }

  if (b.sequence === 'first') {
    return 1;
  }

  return 0;
}

function getAuthorsString(author) {
  if (!author || !author.length) return '';

  return author.sort(sortAuthors)
    .map((singleAuthor) => {
      return `${singleAuthor.family}, ${singleAuthor.given}`;
    })
    .join('; ');
}

function getArticleDate(message) {
  let dateData;
  if (message['published-print']) {
    dateData = message['published-print'];
  } else if (message['published-online']) {
    dateData = message['published-online'];
  } else if (message['published']) {
    dateData = message['published'];
  } else if ((message['journal-issue'] || {})['published-print']) {
    dateData = message['journal-issue']['published-print'];
  } else if ((message['journal-issue'] || {})['published-online']) {
    dateData = message['journal-issue']['published-online'];
  } else if ((message['journal-issue'] || {})['published']) {
    dateData = message['journal-issue']['published'];
  }

  return datePartsToISODate(dateData['date-parts'][0]);

}

function getIssueDate(message) {
  if (!message['journal-issue']) {
    return;
  }

  let dateData;
  if ((message['journal-issue'] || {})['published-print']) {
    dateData = message['journal-issue']['published-print'];
  } else if ((message['journal-issue'] || {})['published-online']) {
    dateData = message['journal-issue']['published-online'];
  } else if ((message['journal-issue'] || {})['published']) {
    dateData = message['journal-issue']['published'];
  }

  return datePartsToISODate(dateData['date-parts'][0]);

}

function getJournalIssn(message) {
  if (!message.ISSN || !message.ISSN.length) {
    return;
  }

  return message.ISSN[0];
}

function getJournalTitle(message) {
  return message['short-container-title'];
}

function getIssueVolume(message) {
  return message.volume;
}

function getIssueNumber(message) {
  if (!message['journal-issue']) {
    return;
  }

  return message['journal-issue'].issue;
}


export default class CrossrefService extends Service {
  @service auth;
  @service analytics;
  @service applicationSession;

  async getArticle(doi) {
    let response;
    try {
      const timeoutMs = 1000;
      const selectedLibraryId = this.applicationSession.selectedLibrary;
      if (selectedLibraryId && selectedLibraryId !== 'unaffiliated') {

        const authService = this.auth;
        const authHeader = authService.getAPIAuthHeaderForLibrary(selectedLibraryId);

        response = await timeoutPromise(timeoutMs,
          fetch(`${API_BASE}/crossref/${doi}`, {
            headers: {
              'Authorization': authHeader
            },
          }),
          `Request to Crossref timed out after ${timeoutMs}ms`);
      } else {
        response = await timeoutPromise(timeoutMs,
          fetch(`https://api.crossref.org/works/${doi}?mailto=info@thirdiron.com`),
          `Request to Crossref timed out after ${timeoutMs}ms`);
      }

      if (response.status !== 200) {
        return;
      }

      const responseBody = await response.json();

      if (responseBody.message.type !== 'journal-article') {
        return;
      }

      const rawTitle = responseBody.message.title[0];
      let title;
      title = rawTitle.replace(/\$\$[^$]+\$\$/g, ' '); // Remove anything surrounded by $$
      title = title.replace(/(<\/?(?!\/?[bi]>)[^<>]+>)/g, ' '); // Anything not <i> or <b> remove
      title = title.replace(/(<(\/[bi])>)/g, '$1 '); // Put a space after </i> or </b>
      title = title.replace(/(<(\/[bi])>)/g, '');
      title = title.replace(/(<([bi])>)/g, '');

      const authors = getAuthorsString(responseBody.message.author);
      const [startPage, endPage] = (responseBody.message.page || '').split('-');
      const articleDate = getArticleDate(responseBody.message);
      const issueDate = getIssueDate(responseBody.message);
      const journalIssn = getJournalIssn(responseBody.message);
      const journalTitle = getJournalTitle(responseBody.message);
      const issueVolume = getIssueVolume(responseBody.message);
      const issueNumber = getIssueNumber(responseBody.message);
      const hasNoEndPage = !endPage;
      const startPageEqualsEndPage = startPage === endPage;
      const isSinglePage = hasNoEndPage || startPageEqualsEndPage;

      const journal = {
        id: 'crossrefJournal',
        title: journalTitle,
        issn: journalIssn,
        coverURL: 'https://assets.thirdiron.com/default-journal-cover.png',
      };

      const issue = {
        date: issueDate || articleDate,
        volume: issueVolume,
        issueNumber
      };

      issue.year = issue.date.substring(0, 4);

      if (issueVolume && issueNumber) {
        issue.title = `Vol ${issueVolume}, Issue ${issueNumber}`;
      } else if (issueVolume && !issueNumber) {
        issue.title = `Vol ${issueVolume}`;
      } else if (!issueVolume && issueNumber) {
        issue.title = `Issue ${issueNumber}`;
      }

      return {
        title,
        strippedTitle: title, // journal-box expects title here
        authors,
        startPage,
        endPage,
        articleDate,
        doi,
        hasNoEndPage,
        startPageEqualsEndPage,
        isSinglePage,
        journal,
        issue,
      };
    } catch (err) {
      console.log(err);
      if (err.code == 'timeout') {
        try {
          await this.analytics.recordEvent({
            category: "CrossrefTimeout",
            action: doi,
          });
        } catch (err) {
          // Do nothing, just prevent error from bubbling up
        }
      }
      return;
    }
  }
}

