
export function getLocalStorageJsonProperty(key) {
  if (!localStorage.getItem(key)) {
    return undefined;
  }
  return JSON.parse(localStorage.getItem(key));
}

export function setLocalStorageJsonProperty(key, value) {
  if (!value) {
    localStorage.removeItem(key);
    return value;
  }

  localStorage.setItem(key, JSON.stringify(value));

  return value;
}
