import Service, { inject as service } from '@ember/service';
import config from 'libkey-web/config/environment';
import fetch from 'fetch';
import timeoutPromise from 'libkey-web/utils/timeout-promise';
const API_BASE = config.apiBaseUrl;

function getAuthorsString(authors) {
  if (!authors || !authors.length) return '';

  return authors.map((a) => a.name)
    .join('; ');
}

function getArticleDate(pubdate) {
  const parsedDate = new Date(pubdate);

  return parsedDate.toISOString().split('T')[0];
}

export default class PubmedService extends Service {
  @service auth;  @service analytics;  @service applicationSession;
  async getArticle(pmid) {
    let response;
    try {
      const timeoutMs = 3000;
      const selectedLibraryId = this.applicationSession.selectedLibrary;
      if (selectedLibraryId && selectedLibraryId !== 'unaffiliated') {

        const authService = this.auth;
        const authHeader = authService.getAPIAuthHeaderForLibrary(selectedLibraryId);

        response = await timeoutPromise(timeoutMs,
          fetch(`${API_BASE}/pubmed/${pmid}`, {
            headers: {
              'Authorization': authHeader
            },
          }),
          `Request to PubMed endpoint timed out after ${timeoutMs}ms`);
      } else {
        return;
      }

      if (response.status !== 200) {
        return;
      }

      const responseBody = await response.json();
      const pmidResult = responseBody[pmid];

      if (!pmidResult ||
        !!pmidResult.error) {
        return;
      }

      const title = pmidResult.title;

      const authors = getAuthorsString(pmidResult.authors);
      const [startPage, endPage] = (pmidResult.pages || '').split('-');
      const articleDate = getArticleDate(pmidResult.pubdate);
      const journalIssn = pmidResult.issn;
      const journalTitle = pmidResult.fulljournalname;
      const issueVolume = pmidResult.volume;
      const issueNumber = pmidResult.issue;
      const hasNoEndPage = !endPage;
      const startPageEqualsEndPage = startPage === endPage;
      const isSinglePage = hasNoEndPage || startPageEqualsEndPage;

      const journal = {
        id: 'pmidJournal',
        title: journalTitle,
        issn: journalIssn,
        coverURL: 'https://assets.thirdiron.com/default-journal-cover.png',
      };

      const issue = {
        date: articleDate,
        volume: issueVolume,
        issueNumber
      };

      issue.year = issue.date.substring(0, 4);

      if (issueVolume && issueNumber) {
        issue.title = `Vol ${issueVolume}, Issue ${issueNumber}`;
      } else if (issueVolume && !issueNumber) {
        issue.title = `Vol ${issueVolume}`;
      } else if (!issueVolume && issueNumber) {
        issue.title = `Issue ${issueNumber}`;
      }

      return {
        title,
        strippedTitle: title, // journal-box expects title here
        authors,
        startPage,
        endPage,
        articleDate,
        hasNoEndPage,
        startPageEqualsEndPage,
        isSinglePage,
        journal,
        issue,
      };
    } catch (err) {
      console.log(err);
      if (err.code == 'timeout') {
        try {
          await this.analytics.recordEvent({
            category: "PubMedTimeout",
            action: pmid,
          });
        } catch (err) {
          // Do nothing, just prevent error from bubbling up
        }
      }
      return;
    }
  }
}

