import Component from "@glimmer/component";
import { task, timeout } from "ember-concurrency";
import { macroCondition, isTesting } from "@embroider/macros";
import { assert } from "@ember/debug";
import { set } from "@ember/object";

export default class ConfirmCompleteRequestComponent extends Component {
  constructor() {
    super(...arguments);
    assert("fulfillmentRequest is required", this.args.fulfillmentRequest);
    assert("onComplete is required", this.args.onComplete);
    assert("onCancel is required", this.args.onCancel);
  }

  @task({ restartable: true })
  *updateRequestTask() {
    if (macroCondition(isTesting())) {
      yield timeout(0);
    } else {
      yield timeout(500);
    }

    const fulfillmentRequest = this.args.fulfillmentRequest;
    if (fulfillmentRequest.status !== "complete") {
      set(fulfillmentRequest, "status", "complete");
      yield fulfillmentRequest.save();
    }

    this.args.onComplete();
  }
}
