import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class SplashPanel extends Component {
  @service customizations;
  @service applicationSession;

  get customStyle() {
    return htmlSafe(this.customizations.customStyle);
  }
}
