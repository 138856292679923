import Model, { belongsTo, attr, hasMany } from "@ember-data/model";

export default class Journal extends Model {
  @belongsTo('library', { async: true })
  library;

  @attr('string')
  title;

  get sortTitle() {
    let title = this.title.toLowerCase();
    title = title.replace(/^(a\s+|an\s+|the\s+)/, '');
    title = title.replace(/&/g, 'and');
    return title;
  }

  @attr('boolean')
  available;

  @attr('string')
  rawCoverURL;

  get coverURL() {
    let rawCoverURL = this.rawCoverURL || '';
    return rawCoverURL.replace('http:', 'https:');
  }

  @attr('string')
  issn;

  @attr('string')
  eissn;

  @attr('number')
  scimagoRank;

  @attr('string')
  scimagoURL;

  @attr('string')
  embargoDescription;

  @hasMany('issue', {async: true})
  issues;

  @belongsTo('issue', {async: true})
  currentIssue;

  @belongsTo('issue', {async: true})
  latestFullTextIssue;

  @hasMany('article', {async: true})
  articlesInPress;

  @hasMany('publicationYear', {async: true})
  publicationYears;

  // There isn't any endpoint to request all articles
  // This is only here so that the relationship from an individual article
  // can specify it as its inverse relation so ember data doesn't
  // get confused and decide articlesInPress is the inverse relation
  // for all articles
  @hasMany('article', {async: true})
  allArticles;

  @attr('string')
  externalSearchLocation;

  @attr('string')
  externalSearchLinkMessage;

  @attr('boolean')
  accessedThroughAggregator;

  @attr('string')
  articlesInPressAvailabilityMessage;

  @attr('string')
  problematicDomainNoticeUrl;

  @attr()
  problematicJournalTopViolations;

  @attr('string')
  problematicJournalCabellsUrl;
}
