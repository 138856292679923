import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { task, timeout } from "ember-concurrency";
import { macroCondition, isTesting } from "@embroider/macros";
import { set } from "@ember/object";
import { assert } from "@ember/debug";

export default class ConfirmDeclineRequestComponent extends Component {
  @service store;

  constructor() {
    super(...arguments);
    assert("@fulfillmentRequest is required", this.args.fulfillmentRequest);
    assert("@onDecline is required", this.args.onDecline);
  }

  @tracked declinedReasonValue = "";

  @task({ restartable: true })
  *updateRequestTask() {
    if (macroCondition(isTesting())) {
      yield timeout(0);
    } else {
      yield timeout(500);
    }

    const declinedReasonValue = this.declinedReasonValue;
    const fulfillmentRequest = this.args.fulfillmentRequest;
    set(fulfillmentRequest, "status", "declined");
    set(fulfillmentRequest, "declinedReason", declinedReasonValue);

    try {
      yield fulfillmentRequest.save();
    } catch (error) {
      console.error("Error saving fulfillment request", error);
      return;
    }

    this.args.onDecline();
  }
}
