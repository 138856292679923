import Service from '@ember/service';

/*
* A simple service for setting the User-Agent, which can be mocked for testing
*/
export default class UserAgentService extends Service {
  get isIOS() {
    return navigator.userAgent.match(/(iPhone|iPod|iPad|iOS)/i);
  }

  get isSafari() {
    return navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i);
  }

  get isAndroid() {
    return navigator.userAgent.match(/Android/i);
  }

  get isEdge() {
    return navigator.userAgent.match(/Edge/i);
  }

  isTest = false;

  get platform() {
    if(this.isIOS) {
      return 'iOS';
    } else if(this.isAndroid) {
      return 'Android';
    } else {
      return 'BZ Web';
    }
  }
}
