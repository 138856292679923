import Model, { attr } from "@ember-data/model";

export default class FulfillmentRequest extends Model {
  @attr('string')
  requesterEmail;

  @attr('string')
  requesterLibraryId;

  @attr('string')
  articleId;

  @attr('string')
  lenderLibraryId;

  @attr('string')
  libraryGroupId;

  @attr('string')
  status;

  @attr('string')
  declinedReason;

  @attr('string')
  customReference;

  @attr('date')
  lastUpdated;

  @attr('date')
  created;
}
