import Mixin from '@ember/object/mixin';
import { doiRegex } from '../utils/doi-utils';

function checkQueryParamsForId(transition, valueCheckingFunction) {
  let { to: { queryParams }, intent } = transition;
  if (intent.url) {
    const [, queryString] = intent.url.split('?');
    const searchParams = new URLSearchParams(queryString);

    for (let paramValue of searchParams.values()) {
      let foundId = valueCheckingFunction(paramValue);
      if (foundId) {
        return foundId;
      }
    }
  } else {
    const emberQueryParams = queryParams;
    for (let paramName of Object.keys(emberQueryParams)) {
      const paramValue = emberQueryParams[paramName];

      let foundId = valueCheckingFunction(paramValue);
      if (foundId) {
        return foundId;
      }
    }
  }
}


// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  checkQueryParamsForPmid(transition) {
    let { to: { queryParams }, intent } = transition;

    function checkValueForPmid(paramValue) {
      const pmidRegex = /^((info:pmid\/)|(pmid:))(\d+)$/
      const matchAttempt = pmidRegex.exec(paramValue);
      if (matchAttempt) {
        return matchAttempt[4];
      }
    }

    // First look for an explicit pmid param
    let pmidValue;
    if (intent.url) {
      const [, queryString] = intent.url.split('?');
      const searchParams = new URLSearchParams(queryString);

      pmidValue = searchParams.get("pmid");
    } else {
      pmidValue = queryParams?.pmid;
    }

    if (pmidValue) {
      return pmidValue;
    }

    // Otherwise search all the query params for something that says its a pmid
    return checkQueryParamsForId(transition, checkValueForPmid);
  },
  checkQueryParamsForDOI(transition) {
    function checkValueForDOI(paramValue) {
      const matchAttempt = doiRegex.exec(paramValue);
      if (matchAttempt) {
        return matchAttempt[0];
      }
    }

    return checkQueryParamsForId(transition, checkValueForDOI);
  },
});
