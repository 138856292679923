function extractAuthority(url) {
  //Authority is defined in the URI RFC as userinfo@host:port
  //https://tools.ietf.org/html/rfc3986#section-3.2
  let authority;
  //find & remove protocol (http, ftp, etc.)
  if (url.indexOf("://") > -1) {
    authority = url.split('/')[2];
  } else {
    authority = url.split('/')[0];
  }
  return authority;
}

export default function getRedirectURLIfProxying(options) {
  const {baseURLForProxyComparison, holdingsToolSubdomains, windowLocationURL} = options;
  let expectedAuthority = extractAuthority(baseURLForProxyComparison);
  if (expectedAuthority && expectedAuthority.length > 0) {
    const holdingsToolExpectedAuthorities = holdingsToolSubdomains.split(',').map(s => `${s}.libkey.io`);
    const appIsServedViaProxy = [expectedAuthority, ...holdingsToolExpectedAuthorities].map(ea => {
      return ea.indexOf(windowLocationURL.host);
    }).every(io => io === -1 );
    if (appIsServedViaProxy) {
      return `${windowLocationURL.protocol}//${expectedAuthority}${windowLocationURL.pathname}${windowLocationURL.search}`;
    }
  }
}
