import RESTSerializer from '@ember-data/serializer/rest';
import Inflector from 'ember-inflector';

function modelHasAttributeOrRelationshipNamedType(modelClass) {
  return modelClass.attributes.has('type') || modelClass.relationshipsByName.has('type');
}

export default class Application extends RESTSerializer {
  isNewSerializerAPI = true;

  modelNameFromPayloadKey(key) {
    return Inflector.inflector.singularize(key);
  }

  /**
   * FIXME This is a fix for an Ember Data bug:
   * https://github.com/emberjs/data/pull/4194
   */
  _normalizePolymorphicRecord(store, hash, prop, primaryModelClass, primarySerializer) {
    let serializer, modelClass;
    const primaryHasTypeAttribute = modelHasAttributeOrRelationshipNamedType(primaryModelClass);
    // Support polymorphic records in async relationships
    if (!primaryHasTypeAttribute && hash.type && store._hasModelFor(this.modelNameFromPayloadKey(hash.type))) {
      serializer = store.serializerFor(this.modelNameFromPayloadKey(hash.type));
      modelClass = store.modelFor(this.modelNameFromPayloadKey(hash.type));
    } else {
      serializer = primarySerializer;
      modelClass = primaryModelClass;
    }

    return serializer.normalize(modelClass, hash, prop);
  }
}
