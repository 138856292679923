/* eslint-disable ember/no-mixins */
import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import RouteAuthHelpers from "../mixins/route-auth-helpers";
import ArticleLoadingRoute from "../mixins/article-loading-route";
import LocalizedRoute from "../mixins/localized-route";
import { scheduleOnce } from "@ember/runloop";

export default class ChooseLibraryNoArticleRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service applicationSession;
  @service store;

  beforeModel() {
    const analytics = this.analytics;
    analytics.setProductDimension('LibkeyIO');
    this.handleLocaleOnLibraryChooserRoute();
  }

  async model() {
    const applicationSession = this.applicationSession;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    let libraries;
    if (holdingsToolLibraryGroup) {
      libraries = await this.store.query("library", {
        "filter[library_group_id]": holdingsToolLibraryGroup?.id,
      });
    } else {
      libraries = await this.store.findAll('library');
      libraries = libraries.filter((library) => {
        const subscriptions = library.subscriptions;
        return subscriptions.libkey && subscriptions.libkey.active;
      });
    }
    return {
      selectableLibraries: libraries,
      holdingsToolLibraryGroup
    };
  }

  setupController(controller, model) {
    if (this.applicationSession.transitioningFromLandingPage) {
      controller.set('leftPanelContentClass', 'invisible');
    }

    controller.set("selectableLibraries", model.selectableLibraries);
    controller.set(
      "handleLocaleOnLibraryRoute",
      this.handleLocaleOnLibraryRoute.bind(this)
    );
    controller.set("holdingsToolLibraryGroup", model.holdingsToolLibraryGroup);

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce("afterRender", this, () => {
      if (!controller.isDestroyed) {
        controller.set("leftPanelContentClass", "fade-in");
      }
    });
  }
}
