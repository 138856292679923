/* eslint-disable ember/no-jquery */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { next } from '@ember/runloop';
import $ from 'jquery';

export default class LanguageList extends Component {
  @service applicationSession;
  @service intl;
  @service media;

  constructor() {
    super(...arguments);

    const handleKeyPress = ((event) => {
      //Esc Key
      if(event.keyCode === 27) {
        next(() => {
          this.hide();
        });
      }
    });

    $(document).on('keyup', handleKeyPress);
  }

  get englishSupported() {
    const library = this.args.library;
    if (!library) {
      return true;
    }

    const languagesSupported = (library.languagesSupported || '').split(',');

    return languagesSupported.any((lang) => lang === 'en-us');
  }

  get frenchSupported() {
    const library = this.args.library;

    if (!library) {
      return false;
    }
    const languagesSupported = (library.languagesSupported || '').split(',');

    return languagesSupported.any((lang) => lang === 'fr-fr');
  }

  get japaneseSupported() {
    const library = this.args.library;

    if (!library) {
      return false;
    }
    const languagesSupported = (library.languagesSupported || '').split(',');

    return languagesSupported.any((lang) => lang === 'ja');
  }

  @action
  hide() {
    if (this.media.isMobile || this.media.isTabletportrait) {
      this.args.hideResponsiveLanguages();
    } else {
      this.args.hideLanguages();
    }
  }

  selectLanguage(language) {
    const currentLocale = this.intl.primaryLocale;
    if (language === currentLocale) {
      if (this.media.isMobile || this.media.isTabletportrait) {
        this.args.hideResponsiveLanguages();
      } else {
        this.args.hideLanguages();
      }
      return;
    }

    this.applicationSession.set('localeOverride', language);
    this.intl.setLocale(language);
    if (this.media.isMobile || this.media.isTabletportrait) {
      this.args.hideResponsiveLanguages();
    }
    this.hide();
    this.args.onSelectLanguage();
  }

  @action
  selectEnglish() {
    this.selectLanguage('en-us');
  }

  @action
  selectFrench() {
    this.selectLanguage('fr-fr');
  }

  @action
  selectJapanese() {
    this.selectLanguage('ja');
  }
}
