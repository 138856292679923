/* eslint-disable ember/no-mixins */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import LocalizedRoute from '../mixins/localized-route';
import ArticleLoadingRoute from "../mixins/article-loading-route";

export default class HoldingsToolFulfillmentRequestRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service applicationSession;
  @service store;

  async model(params) {
    const libraryId = params.library_id;
    const fulfillmentRequestId = params.fulfillment_request_id;
    const applicationSession = this.applicationSession;
    applicationSession.set('selectedLibrary', libraryId);
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    const fulfillmentRequest = await this.store.findRecord('fulfillment-request', fulfillmentRequestId);
    const requesterLibraryId = fulfillmentRequest.requesterLibraryId;
    const requesterLibrary = await this.store.findRecord('library', requesterLibraryId);
    const requesterLibraryName = requesterLibrary.name;
    const lenderLibraryId = fulfillmentRequest.lenderLibraryId;
    const lenderLibrary = await this.store.queryRecord("library", {
      id: lenderLibraryId,
      ill_library_group_id: holdingsToolLibraryGroup
        ? holdingsToolLibraryGroup.id
        : undefined,
      reload: true,
    });
    const lenderLibraryName = lenderLibrary.name;
    const lenderLibraryEmail = lenderLibrary.holdingsToolEmail;
    const selectedLibraryIsRequesterLibrary = libraryId === requesterLibraryId;
    const articleId = fulfillmentRequest.articleId;
    return this.prepareArticleLoadingBoxModel(
      this.store.findRecord("article", articleId, {
        reload: true,
        include: "issue,journal",
      }),
      this.store.findRecord("library", libraryId)
    ).then((model) => {
      return {
        ...model,
        fulfillmentRequest,
        holdingsToolLibraryGroup,
        selectedLibraryIsRequesterLibrary,
        requesterLibrary,
        lenderLibrary,
        requesterLibraryName,
        lenderLibraryName,
        lenderLibraryEmail,
      };
    });
  }

  setupController(controller, model) {
    controller.set('rightPanelCoverClass', this.rightPanelCoverClass);
    controller.set('article', model.article);
    controller.set('issue', model.issue);
    controller.set('journal', model.journal);
    controller.set('holdingsToolLibraryGroup', model.holdingsToolLibraryGroup);
    controller.set('library', model.library);
    controller.set('selectedLibraryIsRequesterLibrary', model.selectedLibraryIsRequesterLibrary);
    controller.set('fulfillmentRequest', model.fulfillmentRequest);
    controller.set('requesterLibraryName', model.requesterLibraryName);
    controller.set('lenderLibraryName', model.lenderLibraryName);
    controller.set('lenderLibraryEmail', model.lenderLibraryEmail);
    controller.set('linkTarget', '_blank');
  }
}
