import Component from '@glimmer/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';

const EmailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export default class RequestArticlePanel extends Component {
  @service intl;
  @service applicationSession;
  @service store;
  @service analytics;
  @service router;

  get buttonDisabled() {
    if (!EmailRegex.test(this.args.requesterEmail)) {
      return 'disabled';
    }
    return '';
  }

  async createFulfillmentRequest() {
    const requesterEmail = this.args.requesterEmail;
    const customReference = this.customReference || '(None provided)';
    const holdingsToolLibraryGroup = this.applicationSession.holdingsToolLibraryGroup;
    const holdingsToolLibraryGroupId = holdingsToolLibraryGroup.id;
    const articleId = this.args.article.id;
    const doi = this.args.article.doi;
    const openAccess = this.args.article.openAccess;
    const authors = this.args.article.authors;
    const lendingLibraryId = this.args.article.illLibraryId;
    const lendingLibraryName = this.args.illLibraryName;
    const requestingLibraryId = this.args.library.id;
    const requestingLibraryName = this.args.library.name;
    const journalName = this.args.journal.title;
    const issn = this.args.journal.issn;
    const eissn = this.args.journal.eissn;
    let articleYear = undefined;
    const articleDate = this.args.article.date;
    if (articleDate) {
      articleYear = articleDate.getFullYear();
    }
    const issue = this.args.issue;
    const issueYear = issue.year;
    const fulfillmentRequest = this.store.createRecord('fulfillment-request', {
      requesterEmail,
      customReference,
      requesterLibraryId: requestingLibraryId,
      articleId,
      lenderLibraryId: lendingLibraryId,
      libraryGroupId: holdingsToolLibraryGroupId
    });
    await fulfillmentRequest.save();
    const fulfillmentRequestId = fulfillmentRequest.id;
    const event = {
      category: 'FulfillmentRequestCreated',
      fulfillmentRequestId,
      referenceNumber: customReference,
      articleId,
      doi,
      lendingLibraryId,
      lendingLibraryName,
      requestingLibraryId,
      requestingLibraryName,
      requesterEmail,
      libraryGroupId: holdingsToolLibraryGroupId,
      journalName,
      articleYear,
      issueYear,
      openAccess,
      authors,
      issn,
      eissn
    };
    try {
      await this.analytics.recordEvent(event);
    } catch (err) {
      console.log(`Error in recordEvent(${JSON.stringify(event)}): ${err.message||err.stack}`);
    }
    return this.router.transitionTo('holdings-tool-fulfillment-request', requestingLibraryId, fulfillmentRequestId);
  }

  @action
  handleCreateFulfillmentRequestSubmit() {
    const buttonDisabled = this.buttonDisabled;
    if (buttonDisabled === 'disabled') {
      return;
    }
    debounce(this, this.createFulfillmentRequest, 3000, true);
  }
}
