import Model from '@ember-data/model';

let alreadyRun = false;

export function initialize(/* application */) {

  // Only reopen and modify the Model class once
  if (!alreadyRun) {
    alreadyRun = true;

    Model.reopen({
      /***
       * There's an [issue](https://github.com/emberjs/data/issues/5046)
       * where the usual methods of accessing relationships can get into
       * trouble if the back end returns an error response.  If an
       * attempt to load relationship data returns an error, not only does
       * the initial attempt to retrieve relationship data resolve to a
       * rejected promise, but subsequent calls immediately return a rejected
       * promise with the initial error without ever attempting another call
       * to the back end.  There appears to be no official way to get a model
       * to retry the request to the, back end, and so we have extended the
       * model class with these workarounds written in terms of the
       * lower level APIs described [here](https://emberjs.com/api/ember-data/2.14.9/classes/DS.BelongsToReference)
       * and [here](https://emberjs.com/api/ember-data/2.14/classes/DS.HasManyReference)
       */
      fixedBelongsToLoad: function fixedBelongsToLoad(relationshipName) {
        const relationshipRef = this.belongsTo(relationshipName);
        let relatedModelPromise = relationshipRef.load();

        // If load immediately returns a promise with an error result in it
        // reload instead. of returning the result of .load()
        if (relatedModelPromise._result && relatedModelPromise._result instanceof Error) {
          return relationshipRef.reload();
        } else {
          return relatedModelPromise;
        }
      },

      fixedHasManyLoad: function fixedHasManyLoad(relationshipName) {
        const relationshipRef = this.hasMany(relationshipName);
        let relatedModelPromise = relationshipRef.load();

        // Due to how the hasMany relationship code is written in ember data
        // we can't immediately distinguish when `.load()` actually tried to
        // contact the back end from when its serving up an error from an earlier
        // request.  For this reason, any time we get an error back try a second time
        // with a `.reload()` call.
        //
        // This results in wasted calls when the back end is emitting errors, but at
        // least doesn't reload relationships that are already loaded successfully.
        return relatedModelPromise
          .catch(() => {
            return relationshipRef.reload();
          });
      }

    });
  }
}

export default { initialize };
