/* eslint-disable ember/no-side-effects */
import Model, { attr } from "@ember-data/model";

export default class Library extends Model {
  @attr('string')
  name;

  @attr()
  aliases;

  @attr('string')
  rawLogo;

  get logo() {
    let rawLogo = this.rawLogo || '';
    return rawLogo.replace('http:', 'https:');
  }

  @attr('string')
  customBackgroundImageUrl;

  get httpsCustomBackgroundImageUrl() {
    let url = this.customBackgroundImageUrl || '';
    return url.replace('http:', 'https:');
  }

  get isFree() {
    return this.subscriptions?.libkey?.type === "free";
  }

  @attr('boolean')
  logoHasLibraryName;

  @attr('boolean')
  promoteNativeApp;

  @attr('string')
  azISSNSearchTemplate;

  @attr('string')
  azPreProxy;

  @attr('string')
  azSystem;

  @attr('string')
  azSystemLibraryId;

  @attr('string')
  azTitleSearchTemplate;

  @attr('string')
  aToZListUrl;

  @attr('string')
  knownJournalLookupTemplate;

  @attr('string')
  promoLink;

  @attr('string')
  promoLabel;

  @attr('string')
  accessProvidedByLink;

  @attr('string')
  accessProvidedByLabel;

  @attr('string', { defaultValue: 'Journals available outside of BrowZine' })
  externalSearchHeader;

  @attr('string')
  infoURL;

  @attr('string')
  webInfoURL;

  @attr()
  subscriptions;

  @attr()
  webLoginInfo;

  @attr('string')
  defaultIssue;

  @attr('boolean')
  supportsILL;

  @attr('string')
  webLoginInfoTitle;

  @attr('string')
  webLoginInfoDescription;

  @attr('string')
  webLoginInfoContinueLabel;

  @attr('string')
  webLoginInfoLinkLabel;

  @attr('string')
  webLoginInfoLinkUrl;

  @attr('string')
  nativeLoginInfoTitle;

  @attr('string')
  nativeLoginInfoDescription;

  @attr('string')
  nativeLoginInfoContinueLabel;

  @attr('string')
  nativeLoginInfoLinkLabel;

  @attr('string')
  nativeLoginInfoLinkUrl;

  @attr('string')
  allBackIssuesMessage;

  @attr('boolean')
  bpsLibrary;

  @attr('boolean')
  forceAuthAtFrontDoor;

  @attr('boolean')
  ssoEnabled;

  @attr('boolean')
  ssoRequiredOnSite;

  @attr('string')
  libKeyInterstitialDisplayFormat;

  @attr('string')
  linkResolverUrlBase;

  @attr('string')
  libkeyLinkDefaultMode;

  @attr('string')
  libkeyCustomPdfLinkLabel;

  @attr('string')
  libkeyCustomArticleLinkLabel;

  @attr('string')
  libkeyCustomTroubleLinkLabel;

  @attr('string')
  libkeyCustomArticleInContextLabel;

  @attr('string')
  linkResolverOpenUrlTemplate;

  @attr('string')
  linkResolverOpenUrlTemplateLabel;

  @attr('string')
  fallbackFulfillmentOption;

  @attr('string')
  emailArticleRequestTemplate;

  @attr('string')
  emailArticleRequestTemplateLabel;

  @attr('string')
  libkeyFallbackTemplate;

  @attr('string')
  libkeyFallbackTemplateLabel;

  @attr('string')
  libkeyFallbackUrl;

  @attr('string')
  libkeyFallbackUrlLabel;

  @attr('string')
  pubmedFallbackTemplate;

  @attr('string')
  pubmedFallbackTemplateLabel;

  @attr('string')
  languagesSupported;

  @attr('boolean')
  useLiveUnpaywallCalls;

  @attr('boolean')
  holdingsToolRequestingEnabled;

  @attr('string')
  holdingsToolEmail;

  @attr("string")
  libraryGroupId;
}
