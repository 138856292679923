import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ArticleLoadingBox extends Component {
  get shouldShowHelp() {
    if (this.args.loadingType === 'fullText') {
      if (this.args.article.openAccess) {
        return false;
      }
      return !this.args.hasArticle || this.args.issue?.embargoed ||
        (this.args.journal?.accessedThroughAggregator && this.args.article?.inPress) ||
        (this.args.journal?.embargoDescription && this.args.article?.inPress);
    } else if ((this.args.loadingType === 'inContext')) {
      return !this.args.article?.browseable;
    }

    return undefined;
  }

  @action
  retry() {
    window.location.reload();
  }
}
