import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

function trimLeadingZeroes(originalValue) {
  if (originalValue === undefined || originalValue === null || originalValue === '')
    return '';

  const trimmedValue = originalValue.replace(/^\s*0+/g, '');

  if (trimmedValue.trim() === '') {
    return '0';
  } else {
    return trimmedValue;
  }
}

const unsupportedTemplateTokens = [
  'article_author_last_name_no_diacritics',
  'article_author_last_name_for_ovid',
  'article_author_first_name',
  'article_doi_for_url_path',
  'article_stored_pdf_url_from_publisher_encoded',
  'article_stored_pdf_url_from_publisher_unencod',
  'journal_issn_with_dash',
  'journal_issn_without_dash',
  'journal_eissn',
  'journal_eissn_with_dash',
  'journal_eissn_without_dash',
  'proquest_id',
  'gale_id'
];

function fillTemplateWithMetadata(template, articleMetadata) {
  const firstAuthor = (articleMetadata.authors.split(';')[0]) || '';

  let authorLastName;
  if (firstAuthor.includes(',')) {
    authorLastName = firstAuthor.split(',')[0] || '';
  } else {
    authorLastName = firstAuthor.split(' ')[0] || '';
  }

  const tokensToValuesMap = [
    {
      token: '{article_author_last_name}',
      value: authorLastName
    },
    {
      token: '{article_authors}',
      value: encodeURIComponent(articleMetadata.authors || '')
    },
    {
      token: '{journal_issn}',
      value: encodeURIComponent(articleMetadata.journal.issn || '')
    },
    {
      token: '{journal_title}',
      value: encodeURIComponent(articleMetadata.journal.title || '')
    },
    {
      token: '{article_title}',
      value: encodeURIComponent(articleMetadata.title || '')
    },
    {
      token: '{issue_volume}',
      value: encodeURIComponent(articleMetadata.issue.volume || '')
    },
    {
      token: '{issue_volume_no_leading_zeroes}',
      value: encodeURIComponent(trimLeadingZeroes(articleMetadata.issue.volume))
    },
    {
      token: '{issue_number}',
      value: encodeURIComponent(articleMetadata.issue.issueNumber)
    },
    {
      token: '{issue_number_no_leading_zeroes}',
      value: encodeURIComponent(trimLeadingZeroes(articleMetadata.issue.issueNumber))
    },
    {
      token: '{article_start_page}',
      value: encodeURIComponent(articleMetadata.startPage || '')
    },
    {
      token: '{article_start_page_no_leading_zeroes}',
      value: encodeURIComponent(trimLeadingZeroes(articleMetadata.startPage))
    },
    {
      token: '{article_end_page}',
      value: encodeURIComponent(articleMetadata.endPage || '')
    },
    {
      token: '{article_end_page_no_leading_zeroes}',
      value: encodeURIComponent(trimLeadingZeroes(articleMetadata.endPage))
    },
    {
      token: '{article_date}',
      value: encodeURIComponent(articleMetadata.articleDate || '')
    },
    {
      token: '{article_year_only_date}',
      value: articleMetadata.articleDate ? articleMetadata.articleDate.substring(0, 4) : ''
    },
    {
      token: '{issue_year_only_date}',
      value: articleMetadata.issue.date ? articleMetadata.issue.date.substring(0, 4) : ''
    },
    {
      token: '{article_doi}',
      value: encodeURIComponent(articleMetadata.doi || '')
    }
  ];

  let result = template;
  for (let j = 0; j < tokensToValuesMap.length; j++) {
    result = result.replace(tokensToValuesMap[j].token, tokensToValuesMap[j].value);
  }

  for (let unsupportedToken of unsupportedTemplateTokens) {
    result = result.replace(unsupportedToken, '');
  }

  return result;

}

export default class ArticleOptions extends Component {
  @service applicationSession;
  @service analytics;
  @service intl;

  @tracked rememberMeChecked = false

  get formatChooserClasses() {
    if (this.args.article?.retractionDoi) {
      return 'retracted';
    }

    return '';
  }

  get articleRetractionReasons() {
    const retractionReasons = this.args.article?.retractionReasons;
    if (!retractionReasons) {
      return undefined;
    }

    return retractionReasons.split(';');
  }

  get articleRetractionRelatedUrls() {
    const retractionRelatedUrlsString = this.args.article?.retractionRelatedUrls;

    if (!retractionRelatedUrlsString) {
      return undefined;
    }

    const retractionRelatedUrls = retractionRelatedUrlsString.split(';').filter(Boolean);

    if (retractionRelatedUrls.length === 1) {
      return [
        {
          label: this.intl.t('retraction_single_related_article_link_text'),
          url: retractionRelatedUrls[0]
        }
      ];
    }

    return retractionRelatedUrls.map((url, index) => {
      return {
        label: this.intl.t('retraction_multiple_related_article_link_text', { linkNumber: index + 1}),
        url,
      };
    });
  }

  get articleRetractionNoticeUrl() {
    const retractionDoi = this.args.article?.retractionDoi;

    if (!retractionDoi) {
      return undefined;
    }

    return `https://doi.org/${retractionDoi}`;
  }

  get articleExpressionOfConcernNoticeUrl() {
    const expressionOfConcernDoi = this.args.article?.expressionOfConcernDoi;

    if (!expressionOfConcernDoi) {
      return undefined;
    }

    return `https://doi.org/${expressionOfConcernDoi}`;
  }

  get articleInContextLink() {
    const article = this.args.article;
    if (!article) {
      return undefined;
    }

    const baseLink = article.browzineWebInContextLink;
    if (!baseLink) {
      return undefined;
    }
    const libkeyProduct = this.args.libkeyProduct;

    if (libkeyProduct) {
      const baseURL = new URL(baseLink);
      baseURL.searchParams.append('utm_source', libkeyProduct);
      return baseURL.toString();
    } else {
      // This shouldn't happen, everything should be assigned to some libkey product, but if
      // it does somehow, default to LibkeyIO
      const baseURL = new URL(baseLink);
      baseURL.searchParams.append('utm_source', 'libkeyio');
      return baseURL.toString();
    }
  }

  get libraryIdForLinks() {
    const library = this.args.library;
    return library ? library.id : 'unaffiliated';
  }

  get crossrefArticleLinkResolverUrl() {
    const library = this.args.library;
    if (!library) {
      return undefined;
    }
    const template = library.linkResolverOpenUrlTemplate;
    if (!template) {
      return undefined;
    }
    const crossrefArticle = this.args.crossrefArticle;
    if (!crossrefArticle) {
      return undefined;
    }

    const result = fillTemplateWithMetadata(template, crossrefArticle);

    return result;

  }

  get crossrefEmailArticleRequestUrl() {
    const library = this.args.library;
    if (!library) {
      return undefined;
    }
    const template = library.emailArticleRequestTemplate;
    if (!template) {
      return undefined;
    }
    const crossrefArticle = this.args.crossrefArticle;
    if (!crossrefArticle) {
      return undefined;
    }

    const result = fillTemplateWithMetadata(template, crossrefArticle);

    return result;

  }

  get pubmedArticleLinkResolverUrl() {
    const library = this.args.library;
    if (!library) {
      return undefined;
    }
    const template = library.linkResolverOpenUrlTemplate
    if (!template) {
      return undefined;
    }
    const pubmedArticle = this.args.pubmedArticle;
    if (!pubmedArticle) {
      return undefined;
    }

    const result = fillTemplateWithMetadata(template, pubmedArticle);

    return result;
  }

  get pubmedEmailArticleRequestUrl() {
    const library = this.args.library;
    if (!library) {
      return undefined;
    }
    const template = library.emailArticleRequestTemplate;
    if (!template) {
      return undefined;
    }
    const pubmedArticle = this.args.pubmedArticle;
    if (!pubmedArticle) {
      return undefined;
    }

    const result = fillTemplateWithMetadata(template, pubmedArticle);

    return result;

  }

  @action
  async didInsert() {
    if (this.args.article?.retractionDoi) {
      await this.analytics.recordEvent({
        category: 'RetractedArticleNoticeViewed',
        action: 'NoticeViewed',
        label: '',
        value: this.args.article?.id,
      });
    } else if (this.args.article?.expressionOfConcernDoi) {
      await this.analytics.recordEvent({
        category: 'EocArticleNoticeViewed',
        action: 'NoticeViewed',
        label: '',
        value: this.args.article?.id,
      });
    } else if (this.args.journal?.problematicJournalCabellsUrl) {
      await this.analytics.recordEvent({
        category: 'problematicJournalNoticeViewed',
        action: 'NoticeViewed',
        label: '',
        value: this.args.journal?.id,
      });
    }
  }

  @action
  async handleFormatOptionClick(formatChoice, extraOnClickFunction) {
    const now = new Date();
    const twentyFourHoursLater = new Date();
    twentyFourHoursLater.setDate(now.getDate() + 1);

    if (this.rememberMeChecked) {
      this.applicationSession.set('formatPreference', {
        format: formatChoice,
        expiresAt: twentyFourHoursLater.toISOString()
      });
    }
    if (extraOnClickFunction) {
      extraOnClickFunction();
    }
  }

  @action
  handleBigCheckboxKeyPress(e) {
    const spaceKeyCode = 32;
    if (e.keyCode === spaceKeyCode) {
      this.rememberMeChecked = !this.rememberMeChecked
    }
  }

}
