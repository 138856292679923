import { helper } from '@ember/component/helper';

export default helper(function pjViolationsByCategory(positional/*, named*/) {
  const topViolations = positional[0];
  const categories = [];
  for (const violation of topViolations) {
    if (!categories.includes(violation.category)) {
      categories.push(violation.category);
    }
  }

  const result = [];
  for (const category of categories) {
    const resultEntry = {
      category,
      violations: []
    };

    for (const violation of topViolations) {
      if (violation.category === category) {
        resultEntry.violations.push(violation);
      }
    }
    result.push(resultEntry);
  }

  return result;
});
