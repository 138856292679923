import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {


  // NOTE - Route names are sent to analytics, check there before changing a route name

  this.route('token-failure', {path: '/token-failure/:library_id'});

  this.route('accept-token', { path: 'libraries/:library_id/accept-token' });

  // TODO: Delete the old route after the public API is switched over to serving links to the new route
  // NOTE - As of 6/28/22 there is still evidence that strongly suggests there are old versions of nomad
  //        still in use that link to this old route.  Only delete after we've determined its ok to break any such
  //        old nomad installs
  this.route('article-content-location', { path: '/libraries/:library_id/articles/:article_id' });
  this.route('new-article-content-location', { path: '/libraries/:library_id/articles/:article_id/content-location' });
  this.route('article-full-text-file', { path: 'libraries/:library_id/articles/:article_id/full-text-file' });

  // Libkey Link routes
  this.route('article-lkl-identifier', { path: 'libraries/:library_id/identifier/*id_value' });
  this.route('article-lkl-identifier-formatchooser', { path: 'libraries/:library_id/formatchooser/identifier/*id_value' });
  this.route('article-lkl-identifier-pdfexpress', { path: 'libraries/:library_id/pdfexpress/identifier/*id_value' });
  this.route('article-lkl-identifier-gotofulltext', { path: '/libraries/:library_id/gotofulltext/identifier/*id_value' });
  // Libkey Link OpenUrl
  this.route('article-openurl', { path: 'libraries/:library_id/openurl' });
  this.route('article-openurl-pdfexpress', { path: '/libraries/:library_id/pdfexpress/openurl' });
  this.route('article-openurl-articlelinkexpress', { path: '/libraries/:library_id/articlelinkexpress/openurl' });
  this.route('article-openurl-formatchooser', { path: '/libraries/:library_id/formatchooser/openurl' });
  this.route('article-openurl-gotofulltext', { path: '/libraries/:library_id/gotofulltext/openurl' });
  // Libkey Link PMID
  this.route('article-pmid', { path: 'libraries/:library_id/pmid/:pmid' });
  this.route('article-pmid-pdfexpress', { path: '/libraries/:library_id/pdfexpress/pmid/:pmid' });
  this.route('article-pmid-articlelinkexpress', { path: '/libraries/:library_id/articlelinkexpress/pmid/:pmid' });
  this.route('article-pmid-formatchooser', { path: '/libraries/:library_id/formatchooser/pmid/:pmid' });
  this.route('article-pmid-gotofulltext', { path: '/libraries/:library_id/gotofulltext/pmid/:pmid' });

  // Linking syntax routes & libkey.io site
  this.route('wayfless-landing-page', { path: '/libraries/:library_id' });
  this.route('wayfless-article-or-pmid', { path: 'libraries/:library_id/*id_value' });
  this.route('wayfless-lookup-route', { path: 'libraries/:library_id/lookup' });

  // Problematic domain page
  this.route('problematic-domain-page', { path: '/libraries/:library_id/problematicdomains/:domain_id' });

  this.route('forwarding-linking-route', { path: '/forwarding/*id_value' });

  this.route('article-or-pmid', { path: '*id_value' });

  this.route('choose-library-no-article', { path: 'choose-library' });
  this.route('choose-library', { path: 'choose-library/*id_value' });

  // Holdings Awareness Tool routes
  this.route('holdings-tool-fulfillment', { path: 'libraries/:library_id/fulfillment/*id_value' });
  this.route('holdings-tool-fulfillment-request', { path: 'libraries/:library_id/fulfillment-request/:fulfillment_request_id' });
});
