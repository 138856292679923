/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RouteAuthHelpers from '../mixins/route-auth-helpers';
import ArticleLoadingRoute from '../mixins/article-loading-route';
import LocalizedRoute from '../mixins/localized-route';


export default class WayflessLookupRouteRoute extends Route.extend(LocalizedRoute, RouteAuthHelpers, ArticleLoadingRoute) {
  @service applicationSession;
  @service intl;
  @service analytics;
  @service router;
  @service store;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    const applicationSession = this.applicationSession;
    const intl = this.intl;

    analytics.setProductDimension('LibkeyIO');

    const libraryId = params.library_id;
    applicationSession.set('selectedLibrary', libraryId);

    await this.handleLocaleOnLibraryRoute();

    const requestedId = queryParams.item;

    if (!requestedId) {
      const error = new Error('required queryParam "item" missing');
      error.errorMessage1 = intl.t('wayfless_lookup_no_id_error_message_1');
      error.errorMessage2 = intl.t('wayfless_lookup_no_id_error_message_2');
      error.suppressTryAgain = true;
      throw error;
    }

    return this.router.transitionTo('wayfless-article-or-pmid', libraryId, requestedId);

  }

  @action
  loading/*transition*/() {
    return false;
  }
}
