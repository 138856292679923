'use strict';

export const pmidRegex = /^\d+$/;

export function findPmidInInput(input) {
  const noWhitespaceInput = input.replace(/\s/g, '');
  let candidatePmid;

  if (noWhitespaceInput.match(pmidRegex)) {
    return {
      foundPmid: true,
      pmid: noWhitespaceInput
    };
  }

  if (noWhitespaceInput.startsWith('http://pubmed.ncbi.nlm.nih.gov/')) {
    candidatePmid = noWhitespaceInput.slice(31);
  } else if (noWhitespaceInput.startsWith('https://pubmed.ncbi.nlm.nih.gov/')) {
    candidatePmid = noWhitespaceInput.slice(32);
  } else {
    candidatePmid = noWhitespaceInput;
  }

  if (candidatePmid.endsWith('.')) { // i.e. copy/pasted citation ending with a period
    candidatePmid = candidatePmid.slice(0, candidatePmid.length - 1);
  }

  if (candidatePmid.endsWith('/')) { // i.e. https://pubmed.ncbi.nlm.nih.gov/12351/
    candidatePmid = candidatePmid.slice(0, candidatePmid.length - 1);
  }

  if (candidatePmid.match(pmidRegex)) {
    return {
      foundPmid: true,
      pmid: candidatePmid
    };
  }

  return {
    foundPmid: false
  };
}
