import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

export default class Article extends JSONAPISerializer {
  keyForAttribute(key) {
    if (key === 'ILLURL') { // abbreviations confuse camelize, so just map ILLURL directly
      return 'ILLURL';
    }
    return camelize(key);
  }

  keyForRelationship(rawKey) {
    return camelize(rawKey);
  }
}
