import { Unpaywall } from 'es6-3i-unpaywall';
import Service, { inject as service } from '@ember/service';

const webLinkValue = 0,
  pdfLinkValue = 1;

export default class UnpaywallService extends Service {
  @service analytics;
  async getUnpaywallUrls(doi) {
    const unpaywall = new Unpaywall(false, window);
    const unpaywallUrls = await unpaywall.getUnpaywallUrls(doi);

    if (!unpaywallUrls.articlePDFUrl &&
      !unpaywallUrls.articleLinkUrl &&
      !unpaywallUrls.manuscriptArticlePDFUrl &&
      !unpaywallUrls.manuscriptArticleLinkUrl) {

      await this.analytics.recordEvent({
        category: 'UnpaywallRetrievedNoUrls',
        action: doi,
        label: '',
        value: ''
      });
    } else {
      const returnedPDFUrl = unpaywallUrls.articlePDFUrl || unpaywallUrls.manuscriptArticlePDFUrl;
      const grabHostRegex = /https?:\/\/([^/]+)\/.*/;
      let host;
      let value;

      if (returnedPDFUrl) {
        host = returnedPDFUrl.match(grabHostRegex)[1];
        value = pdfLinkValue;
      } else {
        const returnedWebUrl = unpaywallUrls.articleLinkUrl || unpaywallUrls.manuscriptArticleLinkUrl;
        host = returnedWebUrl.match(grabHostRegex)[1];
        value = webLinkValue;
      }


      await this.analytics.recordEvent({
        category: 'UnpaywallRetrievedUrls',
        action: doi,
        label: host,
        value
      });
    }

    return unpaywallUrls;
  }
}

