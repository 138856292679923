import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

export default class Journal extends JSONAPISerializer {
  keyForAttribute(key) {
    if (key === 'rawCoverURL') {
      return 'coverURL';
    }
    return key;
  }

  keyForRelationship(rawKey) {
    return camelize(rawKey);
  }
}
