export default function getMatchingHoldingsToolSubdomain(windowLocationHostname, holdingsToolSubdomains) {
  if (holdingsToolSubdomains) {
    const appHostnameWithoutLibkeyIo = windowLocationHostname.replace('libkey.io', '');
    if (appHostnameWithoutLibkeyIo) {
      const possibleHoldingsToolSubdomain = appHostnameWithoutLibkeyIo.split('.')[0];
      return holdingsToolSubdomains.find(subdomain => {
        // (the endsWith option is for feature branch domains, e.g., 'feature-branch-incdocs-develop.libkey.io')
        return possibleHoldingsToolSubdomain === subdomain || possibleHoldingsToolSubdomain.endsWith(`-${subdomain}`);
      });
    }
  }
}
