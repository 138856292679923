import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { resolve } from 'rsvp';

export default class AcceptTokenRoute extends Route {
  queryParams = {
    token: {},
    intent: {}
  };

  @service auth;
  @service analytics;
  @service applicationSession;
  @service router;
  @service store;

  beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const tokenParam = queryParams.token;
    const userTokenParam = queryParams.userToken;

    if (!tokenParam) {
      // Somehow ended up on the accept-token route but without a token to accept!
      throw new Error(`There was an error trying to authorize LibKey to access library "${name}"`);
    }

    const libraryId = params.library_id;

    const applicationSession = this.applicationSession;
    applicationSession.storeTokenForLibrary(libraryId, { token: queryParams.token });
    applicationSession.set('selectedLibrary', libraryId);

    return resolve()
      .then(() => {
        if (userTokenParam) {

          // We got a user token. Use it to set up the logged in user
          return this.auth.authenticateUser(undefined, undefined, userTokenParam);
        }
      })
      .then(async () => {

        await this.analytics.recordEvent({category: 'Login', action: 'Login To Library'});

        if (queryParams.intent) {
          // I would generate a UrlTransitionIntent object from this data and call
          // TransitionByIntent (which is what transition.retry does) but UrlTransitionIntent
          // seems to be internal to the Router module.  I'm not sure how to get at its
          // constructor from application code.  Instead I use the closest thing I could
          // find exported from the Router module: handleURL.  It's not 100% clear if there's
          // a subtle difference between this and calling retry on a transition that has
          // a UrlTransitionIntent.  There appears to be a data property on the intent
          // object that could conceivably hold something meaningful.
          const intentProps = JSON.parse(window.atob(queryParams.intent));
          transition.abort();

          if (intentProps.url) {
            return this.router.transitionTo(intentProps.url);
          } else {
            console.log('Malformed intent - do appropriate action here');
          }
        } else {
          // If somehow we didn't get any place we're supposed to go back to,
          // go to the wayfless landing page
          return this.router.transitionTo('wayfless-landing-page', libraryId);
        }
      });

  }
}
