/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import TriggerOnEnter from 'libkey-web/mixins/trigger-on-enter';
import { tracked } from '@glimmer/tracking';

export default class HoldingsToolFulfillmentRequestController extends Controller.extend(TriggerOnEnter) {
  @service applicationSession;
  @service analytics;
  @service router;

  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;

  async performDoubleFade() {
    this.leftPanelContentClass = "fade-out";
    this.rightPanelCoverClass = "fade-in";
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  @action
  fadeOut() {
    const applicationSession = this.applicationSession;

    this.rightPanelCoverClass = "fade-in"

    return new Promise((resolve) => {
      later(() => {
        applicationSession.transitioningFromWayflessArticleOrPmid = true
        resolve();
      }, 500);
    });
  }

  @action
  fadeOutChangeLibrary() {
    this.applicationSession.selectedLibrary = undefined
    return this.performDoubleFade();
  }
}

