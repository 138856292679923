import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class TokenFailureRoute extends Route {
  @service router;
  @service store;
  @service flashMessages;

  model(params) {
    this.flashMessages.alert('Library authentication failed');
    this.router.transitionTo('libraries', {queryParams: {selectedLibrary: params.library_id}});
  }
}
