import Service from "@ember/service";
import config from "libkey-web/config/environment";
import { tracked } from "@glimmer/tracking";
export default class OfflineService extends Service {
  constructor() {
    super(...arguments);
    this.offline = window.Offline;
    this.offline.options = config.emberOffline || {};

    this.offline.on("up", () => {
      this.state = "up";
    });

    this.offline.on("down", () => {
      this.state = "down";
    });
  }

  offline = null;
  @tracked state = "up";

  get isUp() {
    return this.state === "up";
  }

  get isDown() {
    return this.state === "down";
  }

  check() {
    return this.offline.check();
  }
}
