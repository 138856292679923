/* eslint-disable ember/no-jquery */
import { assign } from '@ember/polyfills';
import $ from 'jquery';
import Service, { inject as service } from '@ember/service';
import config from '../config/environment';

export default class ErrorReporterService extends Service {
  @service auth;  @service applicationSession;
  reportLibraryDetectionError() {
    this._reportError('libraryDetectionError');
  }

  reportOpenAccessTokenError() {
    this._reportError('openAccessTokenError');
  }

  reportTooManySubscribeError() {
    this._reportError('tooManySubscriptionAttempts', {
      webPageString: 'coming soon signup - too many subscribe attempts'
    });
  }

  reportUnknownSubscribeError() {
    this._reportError('unknownSubscribeError', {
      webPageString: 'coming soon signup - unknown error'
    });
  }

  reportLibraryAuthenticationError(id) {
    this._reportError('libraryAuthenticationError', {
      libraryBZID: id
    });
  }

  reportJournalWithNoIssuesError(id) {
    this._reportError('journalWithNoIssuesError', {
      journal_bzid: id
    });
  }

  reportFailedArticleShare(response) {
    this._reportError('failedArticleShare', {
      webPageString: JSON.stringify(response)
    });
  }

  reportMyBookshelfUpdateError(error, requests, previousDocument, failedDocument) {
    this._reportError('myBookshelfUpdateError', {
      webPageString: JSON.stringify({
        error,
        requests,
        previousDocument,
        failedDocument
      })
    });
  }

  reportMyArticlesUpdateError(error, requests, previousDocument, failedDocument) {
    this._reportError('myArticlesUpdateError', {
      webPageString: JSON.stringify({
        error,
        requests,
        previousDocument,
        failedDocument
      })
    });
  }

  reportMyBookshelfFetchError(err) {
    this._reportError('myBookshelfFetchError', {
      webPageString: JSON.stringify(err)
    });
  }

  reportMyBookshelfParseError(err) {
    this._reportError('myBookshelfParseError', {
      webPageString: JSON.stringify(err)
    });
  }

  reportMyBookshelfValidationError(failedDocument, errors) {
    this._reportError('myBookshelfValidationError', {
      webPageString: JSON.stringify({failedDocument, errors})
    });
  }

  reportMyArticlesValidationError(failedDocument, errors) {
    this._reportError('myArticlesValidationError', {
      webPageString: JSON.stringify({failedDocument, errors})
    });
  }

  reportMyBookshelfReadValidationError(failedDocument, errors) {
    this._reportError('myBookshelfReadValidationError', {
      webPageString: JSON.stringify({failedDocument, errors})
    });
  }

  reportHelpError(err) {
    this._reportError('helpError', {
      webPageString: JSON.stringify(err)
    });
  }

  reportUnknownError(message, err) {
    console.log(message, err);
    if (err.status !== undefined) {
      this._reportError(message, {
        webPageString: `the server responded with an error: ${JSON.stringify(err)}`,
        lastURLAccessed: err.lastTransitionUrl || window.location.href
      });
    } else {
      this._reportError(message, {
        webPageString: (err.message || message) +": \n" + err.stack,
        lastURLAccessed: err.lastTransitionUrl || window.location.href
      });
    }
  }

  _reportError(error, data) {
    let couchDb;
    const loggedInUser = this.applicationSession.loggedInUser;

    // Browser identifier is namespaced so we can tell them apart from the library tokens that userd to come
    // out of here.
    const identifier = (loggedInUser || {}).email || 'BID-' + this.applicationSession.getBrowserIdentifier();

    if (loggedInUser) {
      couchDb = new URL(loggedInUser.couchdbDatabaseLocation).pathname.replace('/', '');
    }
    var email = $('#user-info-email').text();

    var defaultData = {
      appVersion: config.APP.version,
      lastURLAccessed: window.location.href,
      libraryBZID: this.applicationSession.selectedLibrary,
      webPageString: error,
      platform: 'web',
      platformVersion: window.navigator.appVersion,
      deviceManuf: '',
      deviceModel: '',
      time_until_error: 0.0,
      errorType: error,
      deviceID: identifier,
      email: email
    };

    var mergedData = assign(defaultData, data || {});

    const webPageStringValue = {
      original: mergedData.webPageString,
      localStorage: localStorage,
      couchDb: couchDb
    };

    mergedData.webPageString = JSON.stringify(webPageStringValue);

    if (config.errorReportingHost) {
      $.ajax({
        url: `${config.errorReportingHost}/bzadmin/reportProblem/`,
        type: 'POST',
        data: mergedData
      });
    } else {
      console.log("no errorReportingHost available, so not sending this error: ", mergedData);
    }
  }
}
