import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class LibkeyWafPanelComponent extends Component {
  @tracked selectedEntry = null;

  @action
  chooseEntry(entry) {
    this.selectedEntry = entry;
  }
}
