/**
 * Interprets a JSON API error payload.  When the error corresponds to a
 * custom error type defined in the app, returns the appropriate custom error type.
 * Otherwise returns nothing
 */

const LIBRARY_SESSION_EXPIRED_CODE = 'expired_library_session';
import ApiSessionExpiredError from 'libkey-web/errors/ApiSessionExpiredError';
import AuthRequiredError from 'libkey-web/errors/AuthRequiredError';

export default function interpretError(httpStatus, errorPayload) {
  if (httpStatus === 401 &&
      errorPayload.code === LIBRARY_SESSION_EXPIRED_CODE) {
    // The docs suggest DS.InvalidError may not be appropriate for session expirations
    let error = new ApiSessionExpiredError(errorPayload);
    return error;
  } else if (httpStatus === 401) {
    let error = new AuthRequiredError(errorPayload);
    return error;
  }
}
