/* eslint-disable ember/no-jquery, ember/no-mixins */
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-observers
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { findDoiInInput } from '../utils/doi-utils';
import { findPmidInInput } from '../utils/pmid-utils';
import TriggerOnEnter from 'libkey-web/mixins/trigger-on-enter';

import $ from 'jquery';

export default class IndexController extends Controller.extend(TriggerOnEnter) {
  @service analytics;
  @service auth;
  @service applicationSession;
  @service router;
  @service intl;
  @service media;
  @service store;

  @tracked searchValue = '';
  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;
  @tracked technologyRowClass;
  @tracked inputContainerClass;
  @tracked foregroundPanelClass;
  @tracked slideContainerClass;
  @tracked backgroundPanelClass;
  @tracked library = false;

  get buttonText() {
    if (!this.searchValue) {
      return this.intl.t('controls.search_button_default_text');
    }

    const { foundDoi } = findDoiInInput(this.searchValue);
    if (foundDoi) {
      return this.intl.t('controls.search_button_doi_text');
    }

    const { foundPmid } = findPmidInInput(this.searchValue);

    if (foundPmid) {
      return this.intl.t('controls.search_button_pmid_text')
    }

    return this.intl.t('controls.search_button_error_text');
  }

  get findButtonClass() {
    if (this.buttonText === this.intl.t('controls.search_button_error_text')) {
      return 'invalid';
    }
    return '';
  }

  get findButtonDisabled() {
    if (this.buttonText === this.intl.t('controls.search_button_error_text')) {
      return 'disabled';
    }
    return '';
  }

  async performLeftFade() {
    this.applicationSession.transitioningFromLandingPage = true
    this.leftPanelContentClass = "fade-out";
    this.inputContainerClass = "fade-out";
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 500);
    });
  }

  async performRightFade() {
    this.applicationSession.transitioningFromLandingPage = true
    this.rightPanelCoverClass = "fade-in"
    this.inputContainerClass = "fade-out"
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 500);
    });
  }

  async performRightFadeTransition(searchValue) {
    await this.performRightFade();

    const selectedLibrary = this.applicationSession.selectedLibrary;
    return this.router.transitionTo('wayfless-article-or-pmid', selectedLibrary, searchValue);
  }

  performSlideTransition(searchValue) {
    this.applicationSession.transitioningFromLandingPage = true;
    this.inputContainerClass = "fade-out"
    this.technologyRowClass = "fade-out"
    later(this, () => {
      this.foregroundPanelClass = "mid-animation";
      this.slideContainerClass = "mid-animation";
      later(this, () => {
        this.foregroundPanelClass = "end-of-slide";
        this.backgroundPanelClass = "end-of-slide";
        later(this, () => {
          this.applicationSession.transitioningFromLandingPage = true;
          return this.router.transitionTo('choose-library', searchValue);
        }, 500);
      }, 350);
    }, 350);
  }

  @action
  async fadeOutChangeLibrary() {
    this.applicationSession.selectedLibrary = undefined;
    await this.performLeftFade();
  }

  @action
  async performSearch() {
    const searchValue = this.searchValue;

    // Distinct from 'searchValue' so we can rewrite it
    // without affecting what displays in the search bar
    let valueToSearch = searchValue ? searchValue.trim() : searchValue;

    if (!valueToSearch) {
      return;
    }

    const doiResult = findDoiInInput(valueToSearch);
    const pmidResult = findPmidInInput(valueToSearch);

    if (!doiResult.foundDoi && !pmidResult.foundPmid) {
      return;
    }

    if (doiResult.foundDoi) {
      const endsInPeriod = doiResult.doi[doiResult.doi.length - 1] === '.';
      valueToSearch = doiResult.doi;

      if (endsInPeriod) {

        const getWithPeriod = this.store.queryRecord('article', { doi: doiResult.doi, reload: true, include: 'issue,journal' })
          .catch((err) => {
            if (err.status === 404) {
              return;
            } else {
              throw err;
            }
          });

        const doiWithoutPeriod = doiResult.doi.substring(0, doiResult.doi.length - 1);
        const getWithoutPeriod = this.store.queryRecord('article', { doi: doiWithoutPeriod, reload: true, include: 'issue,journal' })
          .catch((err) => {
            if (err.status === 404) {
              return;
            } else {
              throw err;
            }
          });

        const [articleWithPeriod, articleWithoutPeriod] = await Promise.all([getWithPeriod, getWithoutPeriod]);

        if (!articleWithPeriod && !!articleWithoutPeriod) {
          valueToSearch = doiWithoutPeriod;
        }
      }
    } else if (pmidResult.foundPmid) {
      valueToSearch = pmidResult.pmid;
    } else {
      console.log('Coding error!  Should have returned earlier');
      return;
    }

    if (this.library || this.unaffiliated) {
      return this.performRightFadeTransition(valueToSearch);
    } else {
      return this.performSlideTransition(valueToSearch);
    }
  }

  @action
  hideResponsiveLanguages() {
    $('.language-list-component').css('transform', 'translate(0,0)');
    $('.language-list-component').addClass('test-hidden');
    $('.page-container').css('transform', 'translate(0,0)');
  }
}
