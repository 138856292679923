import EmberError from '@ember/error';

/**
 * An ApiSessionExpiredError is used by our data adapter to signal
 * that the API is rejecting requests because the client's session
 * has expired, and that the application needs to acquire a new
 * session token to resume use of the API.
 *
 * Our API should return a JSON API formatted error object on
 * error responses.  That response is included on the error
 * object here on the payload property for use by application code
 * to assist in taking the correct action in response to the error.
 *
 * @class ApiSessionExpiredError
 */

export default function ApiSessionExpiredError(payload) {
  EmberError.call(this, `The API reported that the client's session has expired`);
  this.payload = payload;
}

ApiSessionExpiredError.prototype = Object.create(EmberError.prototype);
