import Component from "ember-cli-flash/components/flash-message";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";

export default class FlashMessageComponent extends Component {
  @action
  onDidInsert() {
    let owner = getOwner(this);
    let announcer = owner.lookup("service:announcer");
    let message = `${this.args.flash.type}: ${this.args.flash.message}`;
    announcer.announce(message, "assertive");

    super.onDidInsert(...arguments);
  }
}
