import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  id($i) {
    return $i + 1;
  },

  name() {
    return faker.company.name();
  },

  holdings_tool_logo_url() {
    return faker.helpers.arrayElement([
      'https://assets.thirdiron.com/images/librarylogos/NHSLogo.png',
      'https://assets.thirdiron.com/images/librarylogos/library.png',
    ]);
  },

  holdings_tool_subdomain() {
    return faker.helpers.arrayElement(['subdomain-1', 'localhost', 'ill-test']);
  },
});
