export default function datePartsToISODate(dateParts) {
  if (!dateParts || !dateParts.length) {
    return '';
  }

  dateParts = dateParts.map((part) => {
    const partAsString = part.toString();
    if (partAsString.length === 1) {
      return `0${partAsString}`;
    }
    return partAsString;
  })

  if (dateParts.length === 1) {
    dateParts.push('01'); // no month?  assume january
    dateParts.push('01'); // no day of month?  assume 1st
  } else if (dateParts.length === 2) {
    dateParts.push('01'); // no day of month?  assume 1st
  }

  return dateParts.join('-');
}
