'use strict';

// Matches string that contains a DOI
export const doiRegex = /10.\d{4,9}\/[-<>._;()/:A-Z0-9]+/i;

// Matches string that is a DOI
export const strictDoiRegex = /^10.\d{4,9}\/[-<>._;()/:A-Z0-9]+/i;

export function findDoiInInput(input) {
  const noWhitespaceInput = input.replace(/\s/g, '');
  let candidateDoi;

  if (noWhitespaceInput.startsWith('http://doi.org/')) {
    candidateDoi = noWhitespaceInput.slice(15);
  } else if (noWhitespaceInput.startsWith('https://doi.org/')) {
    candidateDoi = noWhitespaceInput.slice(16);
  } else if (noWhitespaceInput.startsWith('http://dx.doi.org/')) {
    candidateDoi = noWhitespaceInput.slice(18);
  } else if (noWhitespaceInput.startsWith('https://dx.doi.org/')) {
    candidateDoi = noWhitespaceInput.slice(19);
  } else {
    candidateDoi = noWhitespaceInput;
  }

  if (candidateDoi.match(strictDoiRegex)) {
    return {
      foundDoi: true,
      doi: candidateDoi
    };
  } else {
    const decodedCandidateDoi = decodeURIComponent(candidateDoi);
    if (decodedCandidateDoi.match(strictDoiRegex)) {
      return {
        foundDoi: true,
        doi: decodedCandidateDoi
      };
    } else {
      return {
        foundDoi: false
      };
    }
  }
}
