import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  actions: {
    triggerOnEnter() {
      let e = arguments[arguments.length - 1];
      if (e.keyCode === 13) {
        let newArguments = [];

        for (let i = 1; i < arguments.length - 1; i++) {
          newArguments.push(arguments[i]);
        }

        this.send(arguments[0], ...newArguments);
      }
    }
  }
});
