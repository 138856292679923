import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
  id(i) {
    return `L${i + 1}`;
  },

  name() {
    return faker.company.name();
  },

  rawLogo() {
    return faker.image.url();
  },

  logo() {
    return this.rawLogo.replace('http:', 'https:');
  },

  logoHasLibraryName() {
    return false;
  },

  supportsILL() {
    return faker.datatype.boolean();
  },

  aToZListUrl() {
    return faker.internet.url();
  },

  libkeyFallbackUrl() {
    return faker.internet.url();
  },

  holdingsToolEmail() {
    return faker.internet.email();
  },

  holdingsToolRequestingEnabled() {
    return false;
  },

  useLiveUnpaywallCalls() {
    return true;
  },

  libkeyLinkDefaultMode() {
    return faker.helpers.arrayElement(['pdf_express', 'format_chooser']);
  },

  webLoginInfo() {
    return {
      title: `${this.name} VPN title`,
      description: `${this.name} VPN description.`,
      url: 'http://example.com/vpn',
      linkLabel: 'More more more',
      continueLabel: 'Continue!',
    };
  },

  subscriptions() {
    return {
      libkey: {
        active: true,
        type: 'subscribed',
      },
    };
  },

  holdingsRequestingEnabled: trait({
    holdingsToolRequestingEnabled: true,
  }),

  vpnLess: trait({
    webLoginInfo: undefined,
    subscriptions: {
      libkey: {
        active: true,
        type: 'subscribed',
      },
    },
  }),
  openAccessOnly: trait({
    webLoginInfo: undefined,
    subscriptions: {
      libkey: {
        type: 'free',
        active: true,
      },
    },
  }),
  disabled: trait({
    webLoginInfo: undefined,
    subscriptions: {
      libkey: {
        active: false,
      },
    },
  }),
  sso: trait({
    ssoEnabled: true,
    subscriptions: {
      libkey: {
        active: true,
        type: 'subscribed',
      },
    },
  }),
  bps: trait({
    bpsLibrary: true,
    secure: true,
    subscriptions: {
      libkey: {
        active: true,
        type: 'subscribed',
      },
    },
  }),

  afterCreate(library, server) {
    if (!library.libraryGroupId) {
      library.update({ libraryGroupId: server.create('library-group').id });
    }
  },
});
