import { inject as service } from '@ember/service';
import window from 'ember-window-mock';
import Mixin from '@ember/object/mixin';
import config from 'libkey-web/config/environment';
import getMatchingHoldingsToolSubdomain from 'libkey-web/utils/get-matching-holdings-tool-subdomain';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  applicationSession: service(),
  analytics: service(),

  async setHoldingsToolLibraryGroup() {
    if (config.holdingsToolSubdomains) {
      const matchingHoldingsToolSubdomain = getMatchingHoldingsToolSubdomain(
        window.location.hostname,
        config.holdingsToolSubdomains.split(",")
      );

      if (matchingHoldingsToolSubdomain) {
        const libraryGroups = await this.store.query("library-group", {
          "filter[holdings_tool_subdomain]": matchingHoldingsToolSubdomain,
        });
        const libraryGroup = libraryGroups?.firstObject;
        this.applicationSession.set("holdingsToolLibraryGroup", libraryGroup);
        this.analytics.setProductDimension("HoldingsTool");
      }
    }
  }
});
